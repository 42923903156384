import React, { Suspense, lazy } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
// import "antd/dist/antd.css";
const WebsiteHeader = lazy(() => import("./components/header/PublicHeader"));
import { Defaultpage } from "./components/404/Defaultpage";
import { LoginLoader } from "./components/loader/LoginLoader";
import AllDomains from "./views/authenticateViews/Admin/subAdminDashboard/domains/AllDomains";
import { DashboardLayout } from "./layouts/DashboardLayout";

import { Profile } from "./views/authenticateViews/Profile";
import { UserProfile } from "./views/authenticateViews/Admin/subAdminDashboard/UserProfile";
import { StudentDefaultDashboard } from "./views/authenticateViews/students/defaultDashboard";
import { Dashboard } from "./views/authenticateViews/SuperAdmin/dashboard";

import { StudentsQuestions } from "./views/authenticateViews/students/assesments/questions/studentsQuestions";
import { StudentResultDetail } from "./views/authenticateViews/students/dashboard/StudentResultDetail";

import AdminProtectRoute from "./utils/adminProtectRoute";
import StudentProtectRoute from "./utils/StudentProtectRoute";
import SuperAdminProtectRoute from "./utils/SuperAdminProtectRoute";
import Courses from "./views/authenticateViews/students/courseRecommendations/Courses";
import { Spin } from "antd";

// import EventsPages from "./views/publicViews/pages/EventsPages";
const EventsPages = lazy(() => import("./views/publicViews/pages/EventsPages"));
const JobsPage = lazy(() => import("./views/publicViews/pages/JobsPage"));
const MockAssessment = lazy(
  () => import("./views/publicViews/pages/MockAssessment")
);
const SpecificEvent = lazy(
  () => import("./views/publicViews/pages/SpecificEvent")
);

import StudentRoutes from "./routes/StudentRoute";
import AdminRoute from "./routes/AdminRoute";
import SuperAdminRoute from "./routes/SuperAdminRoute";
import { Terms } from "./views/authenticateViews/students/assesments/Terms";
import { ExamInstructions } from "./views/authenticateViews/students/assesments/ExamInstructions";
import { AssessmentBySkill } from "./views/authenticateViews/students/assesments/AssessmentBySkill";
import { AssesmentLists } from "./views/authenticateViews/students/assesments/AssesmentLists";
import { Skills } from "./views/authenticateViews/Admin/subAdminDashboard/skills/Skills";
import { Category } from "./views/authenticateViews/Admin/subAdminDashboard/categories/Category";
import { SkillByCategory } from "./views/authenticateViews/students/skill/SkillByCategory";
import { AllSkillList } from "./views/authenticateViews/students/skill/AllSkillsList";
import { AllCategory } from "./views/authenticateViews/students/category/AllCategory";
import { Domain } from "./views/authenticateViews/Admin/subAdminDashboard/domains/Domain";
import StudentWallet from "./views/authenticateViews/students/wallet/StudentWallet";
import HtmlEditor from "./views/authenticateViews/students/assesments/questions/Editor/HtmlEditor";
import StudentCourse from "./views/authenticateViews/students/courseRecommendations/StudentCourse";

import { PrivateAssessment } from "./views/authenticateViews/students/assesments/PrivateAssessment";
import { MyEvents } from "./views/authenticateViews/students/events/MyEvents";
import { SpecificJob } from "./views/publicViews/pages/SpecificJob";
function ApplicationComponent() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <Suspense
              fallback={
                <div
                  style={{
                    position: "fixed",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                >
                  <Spin></Spin>
                </div>
              }
            >
              <WebsiteHeader />
            </Suspense>
          }
        />
        <Route path="/login" element={<LoginLoader />} />
        <Route
          path="/allevents"
          element={
            <Suspense
              fallback={
                <div
                  style={{
                    position: "fixed",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                >
                  <Spin></Spin>
                </div>
              }
            >
              <EventsPages />
            </Suspense>
          }
        />
        <Route
          path="/alljobs"
          element={
            <Suspense
              fallback={
                <div
                  style={{
                    position: "fixed",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                >
                  <Spin></Spin>
                </div>
              }
            >
              <JobsPage />
            </Suspense>
          }
        />
        <Route
          path="/mock-assessments"
          element={
            <Suspense
              fallback={
                <div
                  style={{
                    position: "fixed",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                >
                  <Spin></Spin>
                </div>
              }
            >
              <MockAssessment />
            </Suspense>
          }
        />
        <Route
          path="/specificevent/:id"
          element={
            <Suspense
              fallback={
                <div
                  style={{
                    position: "fixed",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                >
                  <Spin></Spin>
                </div>
              }
            >
              <SpecificEvent />
            </Suspense>
          }
        />
        <Route path="/job/:id" element={<SpecificJob />} />

        <Route path="/htmleditor" element={<HtmlEditor />} />
        <Route path="/htmleditor/:id" element={<HtmlEditor />} />

        {/* First time select role page route */}
        <Route path="profile" element={<UserProfile />} />
        <Route
          path="student/profile/:uuid/skills"
          element={
            <StudentProtectRoute>
              <StudentCourse />
            </StudentProtectRoute>
          }
        />
        {/* subadmin routing start */}
        <Route
          path="/subadmin"
          element={
            <AdminProtectRoute>
              <DashboardLayout />
            </AdminProtectRoute>
          }
        >
          <Route
            index
            element={
              <AdminProtectRoute>
                <AllDomains />
              </AdminProtectRoute>
            }
          />
          {AdminRoute.map((routes, index) => {
            const { path, component: Component } = routes;
            return (
              <Route
                key={index}
                path={path}
                element={
                  <AdminProtectRoute>
                    <Component />
                  </AdminProtectRoute>
                }
              />
            );
          })}
          <Route path="profile" element={React.createElement(Profile)} />
        </Route>
        {/* subadmin routing end */}

        {/* student routing start */}
        <Route
          path="student"
          element={
            <StudentProtectRoute>
              <DashboardLayout />
            </StudentProtectRoute>
          }
        >
          <Route
            index
            element={
              <StudentProtectRoute>
                <StudentDefaultDashboard />
              </StudentProtectRoute>
            }
          />

          {StudentRoutes.map((routes, index) => {
            const { path, component: Component } = routes;
            return (
              <Route
                key={index}
                path={path}
                element={
                  <StudentProtectRoute>
                    <Component />
                  </StudentProtectRoute>
                }
              />
            );
          })}
          <Route path="profile" element={React.createElement(Profile)} />
          <Route
            path="assesment/domain"
            element={
              <StudentProtectRoute>
                <Domain />
              </StudentProtectRoute>
            }
          />

          <Route
            path="assesment/category"
            element={
              <StudentProtectRoute>
                <AllCategory />
              </StudentProtectRoute>
            }
          />

          <Route
            path="assesment/skill"
            element={
              <StudentProtectRoute>
                <AllSkillList />
              </StudentProtectRoute>
            }
          />

          <Route
            path="assesment/category/:categoryId/skills"
            element={
              <StudentProtectRoute>
                <SkillByCategory />
              </StudentProtectRoute>
            }
          />

          <Route
            path="course-recommendations"
            element={
              <StudentProtectRoute>
                <Courses />
              </StudentProtectRoute>
            }
          />
          <Route
            path="my-events"
            element={
              <StudentProtectRoute>
                <MyEvents />
              </StudentProtectRoute>
            }
          />
          <Route
            path="/student/assesment/domain/category/:id"
            element={
              <StudentProtectRoute>
                <Category />
              </StudentProtectRoute>
            }
          />
          <Route
            path="/student/wallet"
            element={
              <StudentProtectRoute>
                <StudentWallet />
              </StudentProtectRoute>
            }
          />
          <Route
            path="/student/assesment/domain/:domainId/category/:categoryId/skills"
            element={
              <StudentProtectRoute>
                <Skills />
              </StudentProtectRoute>
            }
          />
          <Route
            path="/student/assesment/domain/:domainId/category/:categoryId/skill/:skillId/assessment/list"
            element={
              <StudentProtectRoute>
                <AssesmentLists />
              </StudentProtectRoute>
            }
          />

          <Route
            path="/student/assesment/skill/:skillId/assessment/list"
            element={
              <StudentProtectRoute>
                <AssessmentBySkill />
              </StudentProtectRoute>
            }
          />
          <Route
            path="/student/assesment/domain/:domainId/category/:categoryId/skill/:skillId/assessment/:assessmentId/user/:emailId/exam/instruction"
            element={
              <StudentProtectRoute>
                <Terms />
              </StudentProtectRoute>
            }
          />

          <Route
            path="/student/skill/:skillId/assessment/:assessmentId/user/:emailId/assessmentType/:type/exam/instruction"
            element={
              <StudentProtectRoute>
                <Terms />
              </StudentProtectRoute>
            }
          />
          <Route
            path="/student/assesment/:assessmentId/exam/instruction"
            element={
              <StudentProtectRoute>
                <ExamInstructions />
              </StudentProtectRoute>
            }
          />
          <Route
            path="/student/result/assessment/:assessmentId"
            element={
              <StudentProtectRoute>
                <StudentResultDetail />
              </StudentProtectRoute>
            }
          />
        </Route>

        <Route
          path="/student/assessment/:assessmentId/exam/start"
          element={
            <StudentProtectRoute>
              <StudentsQuestions />
            </StudentProtectRoute>
          }
        />
        {/* student routing end */}

        {/* super admin routing start */}
        <Route
          path="/home"
          element={
            <SuperAdminProtectRoute>
              <DashboardLayout />
            </SuperAdminProtectRoute>
          }
        >
          <Route
            index
            element={
              <SuperAdminProtectRoute>
                <Dashboard />
              </SuperAdminProtectRoute>
            }
          />
          {SuperAdminRoute.map((routes, index) => {
            const { path, component: Component } = routes;
            return (
              <Route
                key={index}
                path={path}
                element={
                  <SuperAdminProtectRoute>
                    <Component />
                  </SuperAdminProtectRoute>
                }
              />
            );
          })}

          <Route path="profile" element={React.createElement(Profile)} />
        </Route>
        {/* super admin routing end */}

        <Route path="*" element={<Defaultpage />} />
        <Route path="404" element={<Defaultpage />} />
      </Routes>
    </BrowserRouter>
  );
}

const App = () => {
  return <ApplicationComponent />;
};

export default App;
