import React, { useState } from "react";
import { Landing } from "../../../components/pages/landing/landing";

export default function EventsPages() {
  return (
    <>
      <Landing />
    </>
  );
}
