import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
  getEventsById,
  getRegiterEventsById,
} from "../../../serviceApi/adminApi/EventApi";
import { useNavigate, useParams } from "react-router-dom";
import { deleteCookieToken, setCookie } from "../../../utils";
import { ClockCircleOutlined } from "@ant-design/icons";
import GoBackbutton from "../../../components/buttons/GoBackbutton";
import PublicHeader from "./PublicHeader";
import { DataLoader } from "../../../components/loader/DataLoader";
import PublicDrawer from "../../../components/publicDrawer/PublicDrawer";
import SpeakersSection from "./SpeakersSection";
import { Helmet } from "react-helmet";
export const SpecificEvent = () => {
  const navigate = useNavigate();
  const { loginWithRedirect } = useAuth0();
  const [isVisible, setIsVisible] = useState(false);
  const paramdata: any = useParams();
  const [events, setEvents] = useState<any>([]);
  const [participantsNumber, setParticipantsNumber] = useState(0);
  const [speakers, setSpeakers] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [len, setLen] = useState([]);

  const handleEventRegister = (eventId, name) => {
    setCookie({ eventId }, 1800);
    localStorage.setItem("name", name);
    loginWithRedirect({});
  };
  const getEvent = async () => {
    const data = await getEventsById(paramdata?.id);
    if (JSON.parse(data?.data?.getEventsById[0]?.event).length > 0) {
      const events = JSON.parse(data?.data?.getEventsById[0]?.event);
      setEvents(events[0]);
      setSpeakers(JSON.parse(events[0]?.speaker_details));

      const len: any = Object?.values(
        JSON.parse(events[0]?.speaker_details)[0]
      );
      setLen(len);
      setIsLoading(false);
    } else {
      navigate("/404");
    }
  };

  const getEventParticipants = async () => {
    const data = await getRegiterEventsById(paramdata?.id);
    if (data?.data?.getRegiterEventsById.length > 0) {
      const resultData = data?.data?.getRegiterEventsById;
      const newArray = resultData.map((m) => [m.userId, m]);
      const newMap = new Map(newArray);
      const iterator: any = newMap.values();
      const uniqueResult: any = [...iterator];
      setParticipantsNumber(uniqueResult.length);
    }
  };
  useEffect(() => {
    getEvent();
    getEventParticipants();
    deleteCookieToken("eventId");
    localStorage.removeItem("name");

    return ()=>setIsLoading(true);
  }, []);
  useEffect(() => {
    const toggleVisibility = () => {
      const header: any = document.querySelector(".register-button");
      const scrollTop = window.scrollY;
      if (scrollTop >= 250) {
        setIsVisible(true);
        header?.classList?.add("is-sticky");
      } else {
        setIsVisible(false);
        header?.classList?.remove("is-sticky");
      }
    };

    window.addEventListener("scroll", toggleVisibility);

    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  const time = new Date(events?.event_start_date).toUTCString();
  const endtime = new Date(events?.event_end_date).toUTCString();
  const currentDate = new Date();

  // Define the target date you want to calculate remaining days for
  const targetDate = new Date(events?.event_start_date); // Note: Months are zero-based (0 for January)

  // Calculate the difference in milliseconds between the target date and current date
  const differenceMs = targetDate.getTime() - currentDate.getTime();

  // Convert milliseconds to days
  const remainingDays = Math.ceil(differenceMs / (1000 * 60 * 60 * 24));

  return isLoading ? (
    <DataLoader />
  ) : (
    <>
      <Helmet>
        <title>{events?.name}</title>
        <meta name="description" content={events?.name} />
        <meta property="og:title" content={events?.name} />
        <meta property="og:description" content={events?.name} />
        <meta
          property="og:image"
          content={`${`${process.env.REACT_APP_MEDIA_URL}/api/media/download${events?.image}`}`}
        />
      </Helmet>
      <div className="relative">
        <PublicHeader />
        {isVisible && (
          <>
            <div className="register-button flex justify-between bg-blue-500 d-none d-xl-block pl-5">
              <button
                className="z-50 bg-[#FF561E] focus:outline-none hover:bg-[#f5450b]  text-white w-[210px] h-[50px] rounded-[30px] px-2 m-2"
                onClick={(e) =>
                  handleEventRegister(paramdata?.id, events?.name)
                }
              >
                {" "}
                Register
              </button>
              <div className="m-2 p-2">
                <PublicDrawer />
              </div>
            </div>
          </>
        )}
        <div className="mx-auto max-w-screen-lg px-4 lg:py-16 py-5 md:px-8 lg:px-0">
          <div className="h-[300px] w-full bg-white shadow-lg rounded-xl overflow-hidden">
            <img
              src={`${`${process.env.REACT_APP_MEDIA_URL}/api/media/download${events?.image}`}`}
              alt="event-image"
              className="lg:h-full h-[380px] w-full object-cover object-center"
            />
          </div>
          <div className="bg-white shadow-lg rounded-xl overflow-hidden mt-[50px]">
            <h1 className="p-5 lg:text-[30px] text-2xl font-primary">
              Unlock Your {events?.name} Potential : Join Our Exclusive Online
              Event!
            </h1>
            <div className="flex py-5 flex-col items-center lg:flex-row justify-between">
              <div>
                <div className="flex flex-row lg:pl-[50px] lg:gap-[40px] gap-5">
                  <div className="shadow-md rounded-xl overflow-hidden  h-[50px] w-[50px] p-[15px] border-[#ccc7c7] border-[2px]">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="#1B1B1880"
                      viewBox="0 0 24 24"
                      fillOpacity="1"
                    >
                      <path d="M22.5 3H21V2a1 1 0 0 0-1-1h-1a1 1 0 0 0-1 1v1h-4V2a1 1 0 0 0-1-1h-1a1 1 0 0 0-1 1v1H7V2a1 1 0 0 0-1-1H5a1 1 0 0 0-1 1v1H2.5A1.5 1.5 0 0 0 1 4.5v18A1.5 1.5 0 0 0 2.5 24h20a1.5 1.5 0 0 0 1.5-1.5v-18A1.5 1.5 0 0 0 22.5 3zM19 2h1v3h-1zm-7 0h1v3h-1zM5 2h1v3H5zM2.5 4H4v1a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1V4h4v1a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1V4h4v1a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1V4h1.5a.5.5 0 0 1 .5.5V8H2V4.5a.5.5 0 0 1 .5-.5zm20 19h-20a.5.5 0 0 1-.5-.5V9h21v13.5a.5.5 0 0 1-.5.5z" />
                    </svg>
                  </div>
                  <div>
                    <h1 className="text-[15px] font-[700]">
                      {" "}
                      Event Start Date
                    </h1>
                    <h1 className="text-[14px] font-[350]">{time}</h1>
                  </div>
                </div>
                <div className="flex flex-row lg:pl-[50px] lg:gap-[40px] gap-5">
                  <div className="shadow-md rounded-xl overflow-hidden  h-[50px] w-[50px] p-[15px] border-[#ccc7c7] border-[2px]">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="#1B1B1880"
                      viewBox="0 0 24 24"
                      fillOpacity="1"
                    >
                      <path d="M22.5 3H21V2a1 1 0 0 0-1-1h-1a1 1 0 0 0-1 1v1h-4V2a1 1 0 0 0-1-1h-1a1 1 0 0 0-1 1v1H7V2a1 1 0 0 0-1-1H5a1 1 0 0 0-1 1v1H2.5A1.5 1.5 0 0 0 1 4.5v18A1.5 1.5 0 0 0 2.5 24h20a1.5 1.5 0 0 0 1.5-1.5v-18A1.5 1.5 0 0 0 22.5 3zM19 2h1v3h-1zm-7 0h1v3h-1zM5 2h1v3H5zM2.5 4H4v1a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1V4h4v1a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1V4h4v1a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1V4h1.5a.5.5 0 0 1 .5.5V8H2V4.5a.5.5 0 0 1 .5-.5zm20 19h-20a.5.5 0 0 1-.5-.5V9h21v13.5a.5.5 0 0 1-.5.5z" />
                    </svg>
                  </div>
                  <div>
                    <h1 className="text-[15px] font-[700]"> Event End Date</h1>
                    <h1 className="text-[14px] font-[350]">{endtime}</h1>
                  </div>
                </div>
                <div className="flex flex-row lg:pl-[50px] lg:gap-[40px] gap-5">
                  <div className="shadow-md rounded-xl overflow-hidden  h-[50px] w-[50px] p-[15px] border-[#ccc7c7] border-[2px]">
                    <ClockCircleOutlined />
                  </div>
                  <div>
                    <h1 className="text-[15px] font-[700]">
                      {" "}
                      Event Registration Deadline
                    </h1>
                    <h1 className="text-[14px] font-[350]">
                      {remainingDays <= 0
                        ? "Started"
                        : `${remainingDays} days left`}
                    </h1>
                  </div>
                </div>

                <div className="flex flex-row lg:pl-[50px] lg:gap-[40px] gap-5">
                  <div className="shadow-md rounded-xl overflow-hidden  h-[50px] w-[50px] p-[15px] border-[#ccc7c7] border-[2px]">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="size-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M9.813 15.904 9 18.75l-.813-2.846a4.5 4.5 0 0 0-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 0 0 3.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 0 0 3.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 0 0-3.09 3.09ZM18.259 8.715 18 9.75l-.259-1.035a3.375 3.375 0 0 0-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 0 0 2.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 0 0 2.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 0 0-2.456 2.456ZM16.894 20.567 16.5 21.75l-.394-1.183a2.25 2.25 0 0 0-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 0 0 1.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 0 0 1.423 1.423l1.183.394-1.183.394a2.25 2.25 0 0 0-1.423 1.423Z"
                      />
                    </svg>
                  </div>
                  <div>
                    <h1 className="text-[15px] font-[700]"> Eligibility</h1>
                    <h1 className="text-[14px] font-[350]">
                      Everyone can apply
                    </h1>
                  </div>
                </div>

                <div className="flex flex-row lg:pl-[50px] lg:gap-[40px] gap-5">
                  <div className="shadow-md rounded-xl overflow-hidden h-[50px] w-[50px] p-[15px] border-[#ccc7c7] border-[2px] ">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="#1B1B1880"
                      viewBox="0 0 24 24"
                      fillOpacity="1"
                      className=""
                    >
                      <path
                        fillRule="evenodd"
                        d="M6.759 4h5.482c.805 0 1.47 0 2.01.044.563.046 1.08.145 1.565.392a4 4 0 0 1 1.748 1.748c.247.485.346 1.002.392 1.564.04.502.044 1.11.044 1.838l1.954-1.954c.19-.191.379-.379.543-.517.147-.123.457-.37.885-.403a1.5 1.5 0 0 1 1.259.52c.279.328.323.721.34.912.02.214.019.48.019.75v6.212c0 .27 0 .536-.019.75-.017.19-.061.584-.34.911a1.5 1.5 0 0 1-1.259.521c-.428-.034-.738-.28-.885-.403a9 9 0 0 1-.543-.517L18 14.414c0 .728-.003 1.336-.044 1.838-.046.562-.145 1.079-.392 1.564a4 4 0 0 1-1.748 1.748c-.485.247-1.002.346-1.564.392-.541.044-1.206.044-2.01.044H6.758c-.805 0-1.47 0-2.01-.044-.563-.046-1.08-.145-1.565-.392a4 4 0 0 1-1.748-1.748c-.247-.485-.346-1.002-.392-1.564C1 15.71 1 15.046 1 14.242V9.758c0-.805 0-1.47.044-2.01.046-.563.145-1.08.392-1.565a4 4 0 0 1 1.748-1.748c.485-.247 1.002-.346 1.564-.392C5.29 4 5.954 4 6.758 4ZM16 9.8c0-.857 0-1.439-.038-1.889-.035-.438-.1-.663-.18-.819a2 2 0 0 0-.874-.874c-.156-.08-.38-.145-.819-.18C13.639 6 13.057 6 12.2 6H6.8c-.857 0-1.439 0-1.889.038-.438.035-.663.1-.819.18a2 2 0 0 0-.874.874c-.08.156-.145.38-.18.819C3 8.361 3 8.943 3 9.8v4.4c0 .857 0 1.439.038 1.889.035.438.1.663.18.819a2 2 0 0 0 .874.874c.156.08.38.145.819.18C5.361 18 5.943 18 6.8 18h5.4c.857 0 1.439 0 1.889-.038.438-.035.663-.1.819-.18a2 2 0 0 0 .874-.874c.08-.156.145-.38.18-.819.037-.45.038-1.032.038-1.889zm2.414 2.2L21 14.586V9.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </div>
                  <div>
                    <h1 className="text-[15px] font-[700]">Online</h1>
                    <h1 className="text-[14px] font-[350]">
                      Register to get link
                    </h1>
                  </div>
                </div>
              </div>

              <div className="lg:w-[300px] mx-auto">
                <button
                  className="bg-[#FF561E] focus:outline-none hover:bg-[#f5450b]  text-white w-[230px] py-2 px-5 rounded-[30px]"
                  onClick={(e) =>
                    handleEventRegister(paramdata?.id, events?.name)
                  }
                >
                  {" "}
                  Register
                </button>
              </div>
            </div>
          </div>
          <div className="bg-white shadow-lg rounded-xl overflow-hidden mt-[50px]">
            <div className=" h-[1px] bg-gray-400 ml-[40px] w-[940px] "></div>
            <div className="gap-[450px] mb-[20px]">
              <h1 className="font-[600] text-[20px] lg:pt-[40px] lg:pl-[20px] p-4">
                {" "}
                Where
              </h1>
              <div className="flex flex-row lg:pt-[10px] lg:pl-[20px] lg:gap-[30px] px-4 gap-5">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="#1B1B18"
                    viewBox="0 0 24 24"
                    fillOpacity="1"
                    className=""
                  >
                    <path
                      fillRule="evenodd"
                      d="M3.223 10a9 9 0 0 0 0 4h3.94A16 16 0 0 1 7 11.98 16 16 0 0 1 7.162 10zm.713-2h3.643a16.3 16.3 0 0 1 2.147-4.71A9.02 9.02 0 0 0 3.936 8M12 3.55A14.3 14.3 0 0 0 9.664 8h4.672A14.3 14.3 0 0 0 12 3.55M14.814 10H9.186q-.162.988-.186 2 .023 1.012.186 2h5.628q.162-.988.186-2a14 14 0 0 0-.186-2m2.024 4A16 16 0 0 0 17 11.98a16 16 0 0 0-.162-1.98h3.939a9 9 0 0 1 0 4h-3.94Zm-2.502 2H9.664A14.3 14.3 0 0 0 12 20.45 14.3 14.3 0 0 0 14.336 16m-4.61 4.71A16.3 16.3 0 0 1 7.579 16H3.936a9.02 9.02 0 0 0 5.79 4.71m4.548 0A16.3 16.3 0 0 0 16.421 16h3.643a9.02 9.02 0 0 1-5.79 4.71M20.064 8h-3.643a16.3 16.3 0 0 0-2.147-4.71A9.02 9.02 0 0 1 20.064 8M1 12C1 5.925 5.925 1 12 1s11 4.925 11 11-4.925 11-11 11S1 18.075 1 12"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </div>
                <div>
                  <h4 className="leading-20 text-[18px] font-medium text-black lg:pl-[20px]">
                    Online
                  </h4>
                </div>
              </div>
            </div>
            <div className=" h-[1px] bg-gray-400 ml-[40px] mb-[20px] w-[940px]"></div>
            <div className="mb-[20px]">
              <h1 className="font-[700] text-[20px] pt-[40px] pl-[20px]">
                {" "}
                Event Details{" "}
              </h1>
              <div className="text-justify whitespace-pre-line break-words font-base p-4 lg:text-lg text-base  text-black block ">
                <div
                  dangerouslySetInnerHTML={{
                    __html: events?.description,
                  }}
                  style={{ overflowWrap: "break-word" }}
                ></div>
              </div>
            </div>

            {/* Event Speaker Details */}
            {len[0] ? (
              <>
                <div className=" h-[1px] bg-gray-400 ml-[40px] mb-[20px] w-[940px]"></div>
                <div className="mb-[20px]">
                  <h1 className="font-[700] text-[20px] pt-[40px] pl-[20px]">
                    {" "}
                    Speakers/Judges{" "}
                  </h1>
                  <SpeakersSection speakers={speakers} />
                </div>
              </>
            ) : (
              ""
            )}

            <div className=" h-[1px] bg-gray-400 ml-[40px] mb-[20px] w-[940px]"></div>
            <div className="pb-5">
              <h1 className="font-[600] text-[20px] px-4">
                {" "}
                Total Participants{" "}
              </h1>
              <h1 className="px-4 mt-0 font-bold">{participantsNumber}</h1>
            </div>
          </div>

          <div className="bg-white shadow-lg rounded-xl overflow-hidden lg:mt-[50px] lg:pb-[30px]">
            <h4 className="leading-20 mt-8 text-[20px] lg:pl-[20px] font-medium text-black">
              This event is part of a community
            </h4>

            <span className="box-border block overflow-hidden  inset-0 lg:pl-[20px] lg:pt-[20px]">
              <img
                alt="Community Image"
                src="https://d2oi1rqwb0pj00.cloudfront.net/community/nio_1722835721164_100.webp"
                className="w-[60px] h-[60px]"
              />
            </span>
            <h3 className="text-[25px] font-bold leading-[26px] text-neutral-10 lg:pl-[20px] lg:pt-[10px]">
              <div className="underline" data-testid="event-community-name">
                Skillcef
              </div>
            </h3>
            <h4 className="leading-20 mt-4 text-[18px] font-medium text-black lg:pl-[20px]">
              Hosted By Skillcef{" "}
            </h4>
          </div>

          <GoBackbutton />
        </div>
      </div>
    </>
  );
};
