import React, { useEffect, useLayoutEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import GoBackbutton from "../../../components/buttons/GoBackbutton";
import PublicHeader from "./PublicHeader";
import { Box, Card, CardContent, Typography, Button } from "@mui/material";
import { getJobById } from "../../../serviceApi/adminApi/JobApi";
import { DataLoader } from "../../../components/loader/DataLoader";
export const SpecificJob = () => {
  const navigate = useNavigate();
  const { loginWithRedirect } = useAuth0();
  const [isVisible, setIsVisible] = useState(false);
  const { id }: any = useParams();
  const [selectedJob, setSelectedJob] = React.useState<any>([]);
  const [jobSkills, setJobSkills] = useState([]);
  const [jobQualifications, setJobQualifications] = useState([]);
  const [jobBenefits, setJobBenefits] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getJobDetails = async () => {
    const data = await getJobById(id);
    if (data?.data?.jobById?.length > 0) {
      setSelectedJob(data?.data?.jobById[0]);
      setJobSkills(JSON?.parse(data?.data?.jobById[0].job_skills));
      setJobQualifications(JSON?.parse(data?.data?.jobById[0]?.qualifications));
      setJobBenefits(JSON?.parse(data?.data?.jobById[0]?.benefits));
      setIsLoading(false);
      return;
    }
    navigate("/404");
  };

  useLayoutEffect(() => {
    getJobDetails();

    return () => setIsLoading(true);
  }, []);


  useEffect(() => {
    const toggleVisibility = () => {
      const header: any = document.querySelector(".register-button");
      const scrollTop = window.scrollY;
      if (scrollTop >= 250) {
        setIsVisible(true);
        header?.classList?.add("is-sticky");
      } else {
        setIsVisible(false);
        header?.classList?.remove("is-sticky");
      }
    };

    window.addEventListener("scroll", toggleVisibility);

    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  return (
    <>
      {isLoading ? (
        <DataLoader />
      ) : (
        <>
          <Helmet>
            <title>{selectedJob?.name}</title>
            <meta name="description" content={selectedJob?.title} />
            <meta property="og:title" content={selectedJob?.title} />
            <meta property="og:description" content={selectedJob?.title} />
            <meta property="og:url" content="https://skillcef.com/"></meta>
            {/* <meta
      property="og:image"
      content={`${`${process.env.REACT_APP_MEDIA_URL}/api/media/download${selectedJob?.image}`}`}
    /> */}
          </Helmet>
          <div className="relative">
            <PublicHeader />
            {isVisible && (
              <div className="register-button d-none d-xl-block pl-5">
                <button
                  className="z-50 bg-[#FF561E] focus:outline-none hover:bg-[#f5450b]  text-white w-[230px] h-[60px] rounded-[30px]"
                  onClick={() => loginWithRedirect({})}
                >
                  {" "}
                  Apply
                </button>
              </div>
            )}
            <div className="mx-auto max-w-screen-lg px-4 lg:py-16 py-5 md:px-8 lg:px-0 ">
              <Box
                sx={{
                  width: "100%",
                  padding: 2,
                  overflowY: "auto",
                  marginRight: 5,
                }}
              >
                <Card key={"id"}>
                  <CardContent>
                    <Typography variant="h5">{selectedJob.title}</Typography>
                    <Typography color="textPrimary">
                      {selectedJob?.organisation}
                    </Typography>
                    <Typography color="textPrimary">
                      {selectedJob?.country},{selectedJob?.region}
                    </Typography>
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{ mt: 2 }}
                      onClick={() => loginWithRedirect({})}
                    >
                      Apply
                    </Button>
                    <Box sx={{ mt: 2, display: "flex", alignItems: "center" }}>
                      <Typography color="textPrimary">
                        {selectedJob?.job_level}
                      </Typography>
                      <Typography color="textPrimary" sx={{ mx: 1 }}>
                        •
                      </Typography>
                      <Typography color="textPrimary">
                        {selectedJob?.job_type}
                      </Typography>
                      <Typography color="textPrimary" sx={{ mx: 1 }}>
                        •
                      </Typography>
                      <Typography color="textPrimary">
                        {selectedJob.job_workingType}
                      </Typography>
                    </Box>
                    <Typography variant="h6" sx={{ mt: 3 }}>
                      Job Highlights
                    </Typography>
                    <Typography variant="body1" sx={{ mt: 1 }}>
                      Job Skills
                    </Typography>
                    {jobSkills?.map((skill, i) => {
                      return (
                        <Typography key={i} variant="body2" color="textPrimary">
                          • {skill}
                          <br />
                        </Typography>
                      );
                    })}
                    <Typography variant="body1" sx={{ mt: 1 }}>
                      Qualifications
                    </Typography>

                    {jobQualifications?.map((qualification, i) => {
                      return (
                        <Typography
                          key={i}
                          variant="body2"
                          color="textPrimary"
                          className="capitalize"
                        >
                          • {qualification} or equavailent
                          <br />
                        </Typography>
                      );
                    })}

                    <Typography variant="body1" sx={{ mt: 2 }}>
                      Benefits
                    </Typography>
                    <Typography variant="body2" color="textPrimary">
                      • The {selectedJob?.country} base salary range for this{" "}
                      {selectedJob?.job_type} position is {selectedJob?.salary}
                      <br />• Our salary ranges are determined by role, level,
                      and location.
                    </Typography>
                    {jobBenefits?.map((benefit, i) => {
                      return (
                        <Typography
                          variant="body2"
                          color="textPrimary"
                          className="capitalize"
                          key={i}
                        >
                          • {benefit}
                          <br />
                        </Typography>
                      );
                    })}
                    <Typography variant="body1" sx={{ mt: 2 }}>
                      Job Description
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textPrimary"
                      className="mr-5"
                      component={"div"}
                    >
                      <div
                        dangerouslySetInnerHTML={{
                          __html: selectedJob?.description,
                        }}
                        className="mr-5"
                        style={{ overflowWrap: "break-word" }}
                      ></div>
                    </Typography>
                  </CardContent>
                </Card>
              </Box>

              <GoBackbutton />
            </div>
          </div>
        </>
      )}
    </>
  );
};
