import React, { useContext, useEffect, useState } from "react";
import { Box, Card, CardContent, Typography, Button } from "@mui/material";
import InfiniteScroll from "../../../../components/infinitescroller/InfiniteScoll";
import {
  applyJob,
  getAllJobs,
  getJobStatusById,
  sendJobNotification,
} from "../../../../serviceApi/adminApi/JobApi";
import { useAuth0 } from "@auth0/auth0-react";
import JobDataFilter from "../../../../components/filter/JobDataFilter";
import JobCard from "../../../../components/card/JobCard";
import { loginContext } from "../../../../components/context/LoginContext";
import { Modal, Progress, message } from "antd";
import Swal from "sweetalert2";
import axios from "axios";
import { getCookie } from "../../../../utils";
import { BreadcrumbComp } from "../../../../components/Breadcrumb/Breadcrumb";
import { Link, useNavigate } from "react-router-dom";
import { DataLoader } from "../../../../components/loader/DataLoader";
import { getCv, getUsers } from "../../../../serviceApi/api";

const JobListing = () => {
  const { role, setRole } = useContext<any>(loginContext);
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();
  const [id, setId] = useState("");
  const { user } = useAuth0();
  const userId: any = user?.email;
  const applicantName: any = user?.nickname;
  const [selectedJob, setSelectedJob] = React.useState<any>([]);
  const [data, setData] = useState<any>([]);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [jobSkills, setJobSkills] = useState([]);
  const [jobQualifications, setJobQualifications] = useState([]);
  const [jobBenefits, setJobBenefits] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [status, setStatus] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [cv, setCv] = useState("");
  const [cvPreview, setCvPreview] = useState("");
  const [cvName, setCvName] = useState("");
  const [checked, setChecked] = useState(false);
  const [isModalOkButtonDisable, setIsModalOkButtonDisable] = useState(true);
  const [progress, setProgress] = useState(0);
  const [open, setOpen] = useState(false);
  const [isCancel, setIsCancel] = useState(false);
  const [userCv, setUserCv] = useState("");


  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleChange = () => {
    setChecked(!checked);
  };

  const handleOk = async () => {
    const res = await applyJob(id, userId, userCv, checked);
    if (res?.data?.appliedJob?.uuid) {
      const formData = new FormData();
      formData.append("image", cv);
      formData.append("uuid", id);
      formData.append("type", "jobCv");
      formData.append("userId", userId);

      if (!checked) {
        try {
          const { data } = await axios.post(
            `${process.env.REACT_APP_MEDIA_URL}/api/media/upload`,
            formData,
            {
              headers: {
                enctype: "multipart/form-data",
                Authorization: `Bearer ${getCookie("token")}`,
              },
              onUploadProgress: (progressEvent: any) => {
                const percentCompleted = Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total
                );
                setProgress(percentCompleted);
              },
            }
          );
          Swal.fire({
            title: "Processing Please Wait!",
            html: "It will apply soon",
            timer: 4000,
            timerProgressBar: true,
            didOpen: () => {
              Swal.showLoading();
            },
          });

          if (data.message == "image uploaded Successfully") {
            const data = {
              jobId: id,
              userId,
              name: selectedJob?.title,
              applicantName: applicantName,
              employerId: selectedJob?.employerId,
              type: "applied",
              message: `Applicant has been applied for the ${selectedJob?.title} position`,
            };
            getJobStatus(id);
            setIsModalOpen(false);
            const res: any = await sendJobNotification(data);
            if (res.data.message) {
              messageApi.open({
                type: "success",
                content: "Email Notification has been sent to the recruiter",
              });
            }
          }
        } catch (error: any) {
          messageApi.open({
            type: "error",
            content: error?.message,
          });
        }
      } else {
        Swal.fire({
          title: "Applied Successfully",
          icon: "success",
          timer: 2000,
          showConfirmButton: false,
        });
        getJobStatus(id);
        setIsModalOpen(false);
        const data = {
          jobId: id,
          userId,
          name: selectedJob?.title,
          applicantName: applicantName,
          employerId: selectedJob?.employerId,
          type: "applied",
          message: `Applicant has been applied for the ${selectedJob?.title} position`,
        };
        const res: any = await sendJobNotification(data);
            if (res.data.message) {
              messageApi.open({
                type: "success",
                content: "Email Notification has been sent to the recruiter",
              });
            }
      }
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setOpen(false);
    setIsCancel(true);
  };

  const fetchData = async (currentPage) => {
    const startIndex = (currentPage - 1) * 4;
    const endIndex = 4;
    setTimeout(async () => {
      const res = await getAllJobs(userId, startIndex, endIndex);
      if (res?.data?.jobList?.length > 0) {
        setIsLoading(false);
        setData([...data, ...res?.data?.jobList]);
        setPage(currentPage + 1);
        setSelectedJob(res?.data?.jobList[0]);
        setId(res?.data?.jobList[0]?.uuid);
        setJobSkills(JSON?.parse(res?.data?.jobList[0]?.job_skills));
        setJobQualifications(
          JSON?.parse(res?.data?.jobList[0]?.qualifications)
        );
        setStatus(res?.data?.jobList[0]?.status);
        setJobBenefits(JSON?.parse(res?.data?.jobList[0]?.benefits));
      } else {
        setHasMore(false);
        setIsLoading(false);
      }
    }, 500);
  };

  useEffect(() => {
    if (data?.length > 0) {
      setSelectedJob(data[0]);
    } else {
      setSelectedJob([]);
    }
  }, [data]);

  useEffect(() => {
    return () => {
      cleanUp();
    };
  }, []);

  const cleanUp = () => {
    setSelectedJob([]);
  };
  const handleJobSelect = async (job) => {
    setSelectedJob(job);
    setId(job?.uuid);
    setJobSkills(JSON?.parse(job?.job_skills));
    setJobQualifications(JSON?.parse(job?.qualifications));
    setJobBenefits(JSON?.parse(job?.benefits));
    setStatus(job?.status);
    getJobStatus(job?.uuid);
  };

  const renderData = () => {
    return isLoading ? (
      ""
    ) : data?.length > 0 ? (
      data?.map((job, i) => (
        <JobCard
          key={i}
          id={i}
          job={job}
          role={role}
          onclick={() => handleJobSelect(job)}
        />
      ))
    ) : (
      <div className="shadow-md flex  flex-row items-center justify-center h-32 w-60 border-gray-300 rounded-md">
        <h1
          className="font-justify-center items-center text-black text-sm"
        >
          No Jobs Found check back later
        </h1>
      </div>
    );
  };
  const handleApply = async () => {
    setIsModalOpen(true);
  };
  const getJobStatus = async (id) => {
    const res = await getJobStatusById(id, userId);
    if (res?.data?.jobAppliedStatus?.length > 0) {
      setStatus(res?.data?.jobAppliedStatus[0].status);
    } else {
      setStatus("");
    }
  };

  const handleApplicants = async () => {
    navigate(`/subadmin/admin/job/${id}/applicants`);
  };

  const userCvupload = async (event: any) => {
    event.preventDefault();
    const files = event.target.files[0];
    if (
      files?.type == "application/pdf" ||
      files?.type ==
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ) {
      if (files.size >= 2097152) {
        Swal.fire({
          icon: "warning",
          title: "file size should be less than 2MB.",
          showConfirmButton: false,
          timer: 3000,
        });
      } else {
        setCv(files);
        setCvPreview(URL.createObjectURL(files));
        setCvName(files?.name);
        setIsModalOkButtonDisable(false);
      }
    } else {
      Swal.fire({
        icon: "warning",
        title: "Invalid CV type",
        showConfirmButton: false,
        timer: 3000,
      });
    }
  };

  const deleteCv = (e) => {
    setCv("");
    setCvPreview("");
    setCvName("");
    setIsModalOkButtonDisable(true);
  };
  const getCvDetails = async (id) => {
    const res = await getCv(id);
    if (res?.data?.getCv?.length > 0) {
      setUserCv(res?.data?.getCv[0].cv);
    }
  };

  const getUserDetails = async () => {
    if (userId != "") {
      const students = await getUsers(userId);
      if (students?.data?.user) {
        getCvDetails(students?.data?.user[0]?.uuid);
      }
    }
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  const breadcrumb = [
    {
      path:
        role == "Student" ? "/student/dashboard" : "/subadmin/AllDomains/list",
      text: "Dashboard",
    },
    {
      text: "All Jobs",
    },
  ];

  return (
    <>
      <div className="max-h-[85vh] font-primary text-black lg:p-10 p-5">
        {contextHolder}
        <p className="text-2xl mb-0 pb-0 font-primary font-medium text-gray-800">
          All Jobs
        </p>
        <div className="sticky top-5 z-20 border-b">
          <div className="mb-4">
            <BreadcrumbComp breadcrumbData={breadcrumb} />
          </div>

          {/* filer added */}
          <div className="mb-5 flex flex-row items-center justify-between gap-x-4 ml-auto lg:w-80 w-full">
            {data?.length > 0 && (
              <div className="sticky top-5 z-20">
                <JobDataFilter jobDetails={setData} />
              </div>
            )}
          </div>
        </div>
        <Box
          sx={{
            display: "flex",
            position: "fixed",
            height: "70vh",
            overflowY: "auto",
            width: "100%",
          }}
          className="grid-cols-2"
        >
          <InfiniteScroll
            fetchData={fetchData}
            renderData={renderData}
            hasMore={hasMore}
            page={page}
          />

          {selectedJob && data?.length > 0 ? (
            <Box
              key={id}
              sx={{
                width: "70%",
                padding: 2,
                overflowY: "auto",
                marginRight: 5,
              }}
            >
              <Card key={id}>
                <CardContent>
                  <Typography variant="h5">{selectedJob.title}</Typography>
                  <Typography color="textPrimary">
                    {selectedJob?.organisation}
                  </Typography>
                  <Typography color="textPrimary">
                    {selectedJob?.country},{selectedJob?.region}
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ mt: 2 }}
                    onClick={role == "Student" ? handleApply : handleApplicants}
                    disabled={
                      status == "Applied"
                        ? true
                        : status == "CV Viewed"
                          ? true
                          : status == "CV Downloaded"
                            ? true
                            : status == "Contacted by email"
                              ? true
                              : status == "Shortlisted"
                                ? true
                                : false
                    }
                  >
                    {role == "Student"
                      ? status == "Applied"
                        ? "Applied"
                        : status == "CV Viewed"
                          ? "CV Viewed"
                          : status == "CV Downloaded"
                            ? "CV Downloaded"
                            : status == "Contacted by email"
                              ? "Contacted by email"
                              : status == "Shortlisted"
                                ? "Shortlisted"
                                : "Apply"
                      : "See Applicants"}
                  </Button>
                  <Box sx={{ mt: 2, display: "flex", alignItems: "center" }}>
                    <Typography color="textPrimary">
                      {selectedJob?.job_level}
                    </Typography>
                    <Typography color="textPrimary" sx={{ mx: 1 }}>
                      •
                    </Typography>
                    <Typography color="textPrimary">
                      {selectedJob?.job_type}
                    </Typography>
                    <Typography color="textPrimary" sx={{ mx: 1 }}>
                      •
                    </Typography>
                    <Typography color="textPrimary">
                      {selectedJob.job_workingType}
                    </Typography>
                  </Box>
                  <Typography variant="h6" sx={{ mt: 3 }}>
                    Job Highlights
                  </Typography>
                  <Typography variant="body1" sx={{ mt: 1 }}>
                    Job Skills
                  </Typography>
                  {jobSkills.map((skill, i) => {
                    return (
                      <Typography key={i} variant="body2" color="textPrimary">
                        • {skill}
                        <br />
                      </Typography>
                    );
                  })}
                  <Typography variant="body1" sx={{ mt: 1 }}>
                    Qualifications
                  </Typography>

                  {jobQualifications?.map((qualification, i) => {
                    return (
                      <Typography
                        key={i}
                        variant="body2"
                        color="textPrimary"
                        className="capitalize"
                      >
                        • {qualification} or equavailent
                        <br />
                      </Typography>
                    );
                  })}

                  <Typography variant="body1" sx={{ mt: 2 }}>
                    Benefits
                  </Typography>
                  <Typography variant="body2" color="textPrimary">
                    • The {selectedJob?.country} base salary range for this{" "}
                    {selectedJob?.job_type} position is {selectedJob?.salary}
                    <br />• Our salary ranges are determined by role, level, and
                    location.
                  </Typography>
                  {jobBenefits?.map((benefit,i) => {
                    return (
                      <Typography
                        variant="body2"
                        color="textPrimary"
                        className="capitalize"
                        key={i}
                      >
                        • {benefit}
                        <br />
                      </Typography>
                    );
                  })}
                  <Typography variant="body1" sx={{ mt: 2 }}>
                    Job Description
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textPrimary"
                    className="mr-5"
                    component={"div"}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: selectedJob?.description,
                      }}
                      className="mr-5"
                    ></div>
                  </Typography>
                </CardContent>
              </Card>
            </Box>
          ) : isLoading && (
            <div className="flex justify-center">
              <DataLoader />
            </div>
          )}
        </Box>

        <Modal
          title="Upload CV"
          open={isModalOpen}
          centered
          onOk={handleOk}
          onCancel={handleCancel}
          okText="Apply"
          okButtonProps={{
            disabled: checked && userCv ? false : isModalOkButtonDisable,
          }}
        >
          <label id="cv">
            <input type="checkbox" checked={checked} onChange={handleChange} />
            <span className="ml-2">Apply with profile CV</span>
          </label>

          {checked ? (
            userCv ? (
              ""
            ) : (
              <>
                <p className="font-bold">Profile Cv not found</p>
                <Link to="/student/profile"> Do you want to upload? </Link>
              </>
            )
          ) : (
            <>
              <h1>Or</h1>
              <h1>
                Upload Cv
                <span className="text-red-500 ml-2">*</span>
              </h1>

              {cvPreview ? (
                <div className="h-48 w-48 group z-50 relative bg-white p-1 rounded-xl border border-dashed border-black">
                  {/* <ImageField image={cvPreview} /> */}
                  <img src="/Assets/pdf_preview.png" />
                  <div
                    onClick={(e) => deleteCv(e)}
                    className="bg-black hover:bg-red-600 cursor-pointer  w-8 h-8 flex items-center justify-center absolute top-1 right-1 rounded-full"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-5 h-5 mx-auto text-white"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                      />
                    </svg>
                  </div>
                </div>
              ) : (
                <>
                  {" "}
                  <button>
                    <div className="flex z-50 relative items-center justify-center  w-48">
                      <label
                        htmlFor="dropzone-file"
                        className="flex flex-col items-center justify-center w-full h-auto border-2 bg-gray-50 border-gray-300 border-dashed rounded-lg cursor-pointer  dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                      >
                        <div className="flex flex-col items-center justify-center pt-5 pb-6">
                          <svg
                            aria-hidden="true"
                            className="w-10 h-10 mb-3 text-gray-400"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                            ></path>
                          </svg>
                          <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                            <span className="font-semibold">
                              Click to upload CV
                            </span>{" "}
                          </p>
                          <p className="text-xs text-center text-gray-500 dark:text-gray-400">
                            PDF (MAX Size. 2MB)
                          </p>
                        </div>
                        <input
                          id="dropzone-file"
                          onChange={userCvupload}
                          type="file"
                          className="hidden"
                        />
                      </label>
                    </div>
                    {progress > 0 && <Progress percent={progress} />}
                  </button>
                </>
              )}
            </>
          )}
          <h1 className="mt-2 text-black text-lg">
            {checked ? userCv?.split("/")?.pop() : cvName}
          </h1>
        </Modal>
      </div>
    </>
  );
};

export default JobListing;
