import React, {
  createContext,
  useState,
  Dispatch,
  SetStateAction,
} from "react";

const loginContext = createContext<AppContextType | null>(null);
export { loginContext };

interface Props {
  children: JSX.Element;
}

interface UserProfile {
  // Define the structure of the user profile
  username: string;
  email: string;
  // ... other properties
}

interface AppContextType {
  role: string | null;
  firstName: string | null;
  loginToken: string | null;
  userProfile: UserProfile | null;
  eventId: string | null;
  isShare: boolean;
  assessmentuuid: string | null;
  isAttempted: boolean;
  isSaved:boolean;
  setRole: Dispatch<SetStateAction<string | null>>;
  setFirstName: Dispatch<SetStateAction<string | null>>;
  setLoginToken: Dispatch<SetStateAction<string | null>>;
  setUserProfile: Dispatch<SetStateAction<UserProfile | null>>;
  setEventId: Dispatch<SetStateAction<UserProfile | null>>;
  setIsShare: Dispatch<SetStateAction<UserProfile | null>>;
  setIsAttempted: Dispatch<SetStateAction<UserProfile | null>>;
  setAssessmentUuid: Dispatch<SetStateAction<UserProfile | null>>;
  setIsSaved: Dispatch<SetStateAction<UserProfile | null>>;
}

const LoginContext = ({ children }: Props) => {
  const [role, setRole] = useState<string | null>("");
  const [firstName, setFirstName] = useState<string | null>("");
  const [loginToken, setLoginToken] = useState<string | null>("");
  const [userProfile, setUserProfile] = useState<UserProfile | null>(null);
  const [eventId, setEventId] = useState<any>("");
  const [isShare, setIsShare] = useState<any>();
  const [assessmentuuid, setAssessmentUuid] = useState<any>();
  const [isAttempted, setIsAttempted] = useState<any>(false);
  const [isSaved, setIsSaved] = useState<any>(false);


  const contextValue: AppContextType = {
    role,
    firstName,
    loginToken,
    userProfile,
    eventId,
    isShare,
    isAttempted,
    assessmentuuid,
    isSaved,
    setRole,
    setFirstName,
    setLoginToken,
    setUserProfile,
    setEventId,
    setIsShare,
    setIsAttempted,
    setAssessmentUuid,
    setIsSaved
  };

  return (
    <loginContext.Provider value={contextValue}>
      {children}
    </loginContext.Provider>
  );
};

export default LoginContext;
