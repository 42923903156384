import AllDomainRequest from "../views/authenticateViews/SuperAdmin/AllDomainRequest";
import CategoryEdit from "../views/authenticateViews/SuperAdmin/CategoryEdit";
import { AdminCreateCategory } from "../views/authenticateViews/SuperAdmin/CreateCategory";
import { CreateDomain } from "../views/authenticateViews/SuperAdmin/createDomain";
import { Dashboard } from "../views/authenticateViews/SuperAdmin/dashboard";
import AllUsers from "../views/authenticateViews/SuperAdmin/AllUsers";

const coreRoutes = [
  {
    path: "dashboard",
    title: "dashboard",
    component: Dashboard,
  },

  {
    path: "allusers",
    title: "allusers",
    component: AllUsers,
  },

  {
    path: "create/domain",
    title: "CreateDomain",
    component: CreateDomain,
  },
  {
    path: "/home/dashboard/domain/:domainId/edit",
    title: "CreateDomain",
    component: CreateDomain,
  },
  {
    path: "domain/requests",
    title: "domain/requests",
    component: AllDomainRequest,
  },
  {
    path: "/home/dashboard/domain/:id/category/create",
    title: "home/dashboard",
    component: AdminCreateCategory,
  },
  {
    path: "/home/dashboard/domain/:domainId/category/:categoryId/edit",
    title: "home/dashboard",
    component: CategoryEdit,
  },
];

const SuperAdminRoute = [...coreRoutes];
export default SuperAdminRoute;
