import React, { useState } from "react";
import "../../views/authenticateViews/Admin/job/JobCard.css";
import { PrimaryButton } from "../buttons/PrimaryButton";
import { InputField } from "../inputFields/InputField";
import { Modal } from "antd";
import { DeleteButton } from "../buttons/DeleteButton";
import { EditButton } from "../buttons/EditButton";
import { DestroyButton } from "../buttons/DestroyButton";

const JobCard = ({ job, role, id, onclick }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [btnText, setBtnText] = useState("Copy Link");
  const origin = window.location.origin;

  const handleCopy = () => {
    var copyText = document.getElementById("link") as HTMLInputElement;

    copyText?.select();

    navigator.clipboard.writeText(copyText?.value);
    setBtnText("Copied");
    setIsCopied(true);
    setTimeout(() => {
      setBtnText("Copy Link");
      setIsCopied(false);
    }, 2000);
  };
  var currentdate = new Date();
  var day = currentdate.getDate();

  var createddate = new Date(job.createdAt);
  var getday = createddate.getDate();

  var currentDate = new Date();

  // Define the target date you want to calculate remaining days for
  var targetDate = new Date(job?.createdAt); // Note: Months are zero-based (0 for January)

  // Calculate the difference in milliseconds between the target date and current date
  var differenceMs = currentDate.getTime() - targetDate.getTime();

  // Convert milliseconds to days
  var remainingDays = Math.ceil(differenceMs / (1000 * 60 * 60 * 24));

  return (
    <>
      <Modal
        title="Share Link"
        centered
        open={isModalOpen}
        className="cat-modal"
        onCancel={() => setIsModalOpen(false)}
      >
        <div>
          <InputField
            type="name"
            placeholder="Name"
            id="link"
            value={`${origin}/job/${job?.uuid}`}
          />
        </div>
        <div className="mt-8">
          <PrimaryButton
            id="copy"
            onclick={() => handleCopy()}
            text={btnText}
            className={`${
              isCopied
                ? "bg-blue-400 hover:bg-blue-400 hover:border-black "
                : "bg-black hover:bg-transparent hover:border-black border-black hover:text-black"
            }`}
          />
        </div>
      </Modal>
      <div
        className="job-card cursor-pointer font-primary"
        onClick={onclick}
        key={id}
      >
        <div className="job-card-header">
          <div className="flex justify-between w-full">
            <div className="bg-blue-500 w-14 h-14  flex justify-center items-center text-white capitalize text-2xl rounded-lg p-5 font-bold font-primary">
              {job?.organisation?.slice(0, 1)}.
            </div>
            <div
              className="bg-blue-500 w-14 h-14 flex justify-center items-center text-white capitalize text-2xl rounded-lg p-5 font-bold font-primary"
              onClick={() => setIsModalOpen(true)}
            >
              <img
                src="/Assets/share.png"
                alt="event-image"
                width={50}
                height={50}
              ></img>
            </div>
          </div>
        </div>
        <div>
          <h2 className="max-w-90 font-primary text-black font-bold">
            {job?.title}
          </h2>
          <h3 className="max-w-60 font-primary text-black font-bold">
            {job?.organisation}
          </h3>
        </div>
        <div className="job-card-body font-primary">
          <p className="max-w-40 font-primary">
            {job?.country}, {job?.region}, {job?.city}
          </p>
          <p className="max-w-40">{job?.type}</p>
          <p className="max-w-60">{job?.salary}</p>
          <li className="max-w-40">{job?.job_role}</li>
          <li>{job?.job_type}</li>
          <li>{job?.job_workingType}</li>
          <li>{job?.status}</li>
          <p className="max-w-60 font-primary">
            Required Experience : {job?.experience} in Years
          </p>
        </div>
        <div className="border-t-2 border-gray-400"></div>
        <div className="job-card-footer flex  flex-row justify-between space-x-2">
          <div>
            <p className="max-w-40">
              {remainingDays == 0
                ? "Today"
                : remainingDays == 1
                  ? `Today`
                  : `${remainingDays} days ago`}
            </p>
          </div>
          {/* 
          {role == "Admin" && (
            <div className="flex felx-row space-x-2">
              <EditButton editRoute="/subadmin/admin/edit-jobs" />
              <DestroyButton
                onClick={() => {
                  console.log("destroy");
                }}
              />
            </div>
          )} */}
        </div>
      </div>
    </>
  );
};

export default JobCard;
