import React, { useContext, useEffect, useState } from "react";
import { LoginButton } from "../buttons/LoginButton";
import newLogo from "../../assets/images/newlogo.svg";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router";
import { getUsers } from "../../serviceApi/api";
import Drawer from "rc-drawer";
import { UserOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { loginContext } from "../context/LoginContext";
import Swal from "sweetalert2";
import { getCookie } from "../../utils";
import PublicDrawer from "../publicDrawer/PublicDrawer";
import { DataLoader } from "../loader/DataLoader";

interface User {
  email: string;
  nickname: string;
  loginWithRedirect: any;
  user: string;
}
export const WebsiteHeader = () => {
  const { user, loginWithRedirect, isAuthenticated } = useAuth0<User>();
  const email = user?.email;
  const navigate = useNavigate();

  const { setRole }: any = useContext(loginContext);
  const [open, setOpen] = useState<boolean>(false);
  const isLoading = true;
  const getUserInfo = async (email: string) => {
    try {
      const userInfo = await getUsers(email);
      if (userInfo?.data?.user?.length > 0) {
        if (userInfo?.data?.user[0]?.role) {
          setRole(userInfo?.data?.user[0]?.role);
          getRole(userInfo?.data?.user[0]?.role);
        }
      } else {
        setRole("new user");
        getRole("new user");
      }
    } catch (error: any) {
      Swal.fire({
        icon: "error",
        title: "Something Went Wrong! Please try again later.",
        showConfirmButton: false,
        timer: 3000,
      });
      // throw new Error(error);
    }
  };
  const token = getCookie("token");
  if (!token) {
    loginWithRedirect({});
  }

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const getRole = (userRole) => {
    if (userRole != null) {
      if (userRole === "SuperAdmin") {
        navigate("/home/dashboard");
      } else if (userRole === "Admin") {
        navigate("/subadmin/AllDomains/list");
      } else if (userRole === "Student") {
        navigate("/student/dashboard");
      } else if (userRole === "new user") {
        navigate("/profile");
      }
    }
  };

  useEffect(() => {
    if ((email && email != "") || email != null) {
      if (isAuthenticated) {
        getUserInfo(email);
      } else {
        getUserInfo(email);
        // getRole("");
      }
    } else {
      loginWithRedirect({});
    }
  }, [email, user]);

  return (
    <>
      {isLoading ? (
        <DataLoader/>
      ) : (
        <section className="absolute top-0 left-0 right-0 z-20">
          <nav className="max-w-5xl mx-auto lg:px-0 px-2 py-2 lg:py-4  flex  flex-row items-center justify-between">
            <img src={newLogo} alt="logo-skillcef" />
            <PublicDrawer/>
            <div className="hidden lg:block md:block">
              <ul className="list-none my-0 text-white flex flex-row space-x-10 text-base items-center font-primary font-medium">
                <li>Home</li>
                <li>Product</li>
                <li>Contact</li>
                <li>Career</li>
                <li>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                    />
                  </svg>
                </li>
                <li onClick={() => loginWithRedirect({})}>
                  <LoginButton />
                </li>
              </ul>
            </div>
          </nav>
        </section>
      )}
    </>
  );
};
