import { QuestionCircleOutlined } from "@ant-design/icons";
import { Popconfirm, Table } from "antd";
import { ColumnsType, TablePaginationConfig } from "antd/es/table";
import { FilterValue, SorterResult } from "antd/lib/table/interface";
import React, { useEffect, useState } from "react";

import { useAuth0 } from "@auth0/auth0-react";
import UserIcon from "../../../assets/leftnavicons/UserIcon.svg";
import { TableSkeleton } from "@ant-design/pro-components";
import { BreadcrumbComp } from "../../../components/Breadcrumb/Breadcrumb";
import { getAllUsers } from "../../../serviceApi/api";

interface DataType {
  name: {
    first: string;
    last: string;
  };
  duration: string;
  question: any;
  id: number;
  description: string;
}

interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
  filters?: Record<string, FilterValue>;
}

const AllUsers = () => {
  const { user } = useAuth0();
  const userId: any = user?.email;
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  const [page, setPage] = React.useState(1);
  const [isResponse, setIsResponse] = React.useState(true);
  const [users, setUsers] = React.useState([]);
  const [item, setItem] = useState("");

  const getAlUsersList = async (name) => {
    if (userId) {
      const response = await getAllUsers(name);
      if (response?.data?.allUsers) {
        const finalUsers = response?.data?.allUsers?.filter(
          (user) => user?.role != "SuperAdmin"
        );
        setUsers(finalUsers);
        setIsResponse(false);
      } else {
        setIsResponse(false);
      }
    }
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "Sr. No.",
      dataIndex: "id",
      width: "5%",
      render: (id, record, index: any) => {
        return (
          <p className="text-center">
            {(page - 1) * Number(tableParams?.pagination?.pageSize) + index + 1}
          </p>
        );
      },
    },

    {
      title: "User Image",
      dataIndex: "image",
      width: "10%",
      defaultSortOrder: "descend",
      render: (image) => {
        return (
          <>
            <p>
              {" "}
              <img
                src={
                  image
                    ? `${process.env.REACT_APP_MEDIA_URL}/api/media/download${image}`
                    : UserIcon
                }
                alt="brandimage"
                className="h-24 w-24 mx-auto rounded-lg"
              />
            </p>
          </>
        );
      },
    },

    {
      title: "First Name",
      dataIndex: "firstName",
      width: "20%",
      defaultSortOrder: "descend",
      render: (firstName) => {
        return (
          <>
            <p className="text-center my-0">{firstName}</p>
          </>
        );
      },
    },

    {
      title: "Last Name",
      dataIndex: "lastName",
      width: "20%",
      defaultSortOrder: "descend",
      render: (lastName) => {
        return (
          <>
            <p className="text-center my-0">{lastName}</p>
          </>
        );
      },
    },

    {
      title: "User ID",
      dataIndex: "userId",
      width: "20%",
      defaultSortOrder: "descend",
      render: (userId) => {
        return (
          <>
            <p className="text-center my-0">{userId}</p>
          </>
        );
      },
    },
    {
      title: "User role",
      dataIndex: "role",
      width: "20%",
      defaultSortOrder: "descend",
      render: (role) => {
        return (
          <>
            <p className="text-center my-0">{role}</p>
          </>
        );
      },
    },

    {
      title: "Action",
      width: "8%",
      dataIndex: "status",
      render: (status, users: any) => {
        return (
          <>
            <div className="flex flex-row justify-center space-x-3">
              <Popconfirm
                title="The 'Delete User' feature announce soon."
                // onConfirm={() =>
                //   requestAccept(
                //     domains.domainId,
                //     domains.userId,
                //     domains.name,
                //     domains?.firstName
                //   )
                //}
                icon={<QuestionCircleOutlined style={{ color: "red" }} />}
              >
                <div className="font-primary cursor-pointer text-center flex flex-col justify-center items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-8 h-8 text-red-500"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                    />
                  </svg>
                </div>
              </Popconfirm>
            </div>
          </>
        );
      },
    },
  ];
  const handleTableChange: any = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue>,
    sorter: SorterResult<DataType>
  ) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });
  };

  const breadcrumbData = [
    {
      path: "/home/dashboard",
      text: "Dashboard",
    },
    {
      text: "All users",
    },
  ];

  const handleChange = (e) => {
    getAlUsersList(e?.target?.value);
  };

  useEffect(() => {
    getAlUsersList(item);
  }, []);

  return (
    <>
      <section className="w-full mt-2 max-h-[86vh] overflow-y-auto lg:p-10 p-5">
        <h1 className="text-2xl  font-primary font-medium text-gray-800">
          All users
        </h1>
        <div className="mb-5">
          <BreadcrumbComp breadcrumbData={breadcrumbData} />
        </div>
        <span className="font-semibold">Total records : {users?.length}</span>
        <div className="mb-2 ml-auto lg:w-64 w-full">
          <input
            type="search"
            placeholder=" Search first name or last name"

            onChange={(e) => {
              handleChange(e);
            }}
            className={`lg:p-2  p-2 text-sm lg:mt-2 border border-gray-300 focus:outline-none w-full rounded-lg`}
          />
        </div>
        {isResponse ? (
          <TableSkeleton active={true} />
        ) : (
          <Table
            className="domain-request-table"
            columns={columns}
            rowKey={(record) => record.id}
            dataSource={users}
            pagination={{
              onChange(current) {
                setPage(current);
              },
              defaultPageSize: 10,
              hideOnSinglePage: false,
              showSizeChanger: true,
            }}
            loading={isResponse}
            onChange={handleTableChange}
          />
        )}
      </section>
    </>
  );
};

export default AllUsers;
