import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "apollo-link-context";
import gql from "graphql-tag";
import { getCookie } from "../../utils";
import Swal from "sweetalert2";

interface DefaultOptions {
  watchQuery: any;
  query: any;
}
interface JobInput {
  title?: string | undefined;
  name?: string | undefined;
  description: string;
  employerId: string | undefined;
  job_role: string | undefined;
  job_type: string | undefined;
  job_level: string | undefined;
  country: string | undefined;
  region: string | undefined;
  status: string;
  experience: string;
  domain: string;
  skills: string;
  job_workingType: string;
  organisation: string;
  salary: string;
  city: string | undefined;
  qualifications: string;
  benefits: string;
  job_start_date: string;
  job_end_date: string;
}

interface ApplicantInput {
  userId: string;
  jobId: string;
  status: string;
}

interface ReviewInput {
  uuid: string;
  userId: string;
  review: string;
  rating: number;
}

interface JobNotificationInput {
  userId: string;
  jobId: string;
  employerId: string;
  name: string;
  type: string;
  message: string;
  applicantName:string;
}

const httpLink: any = createHttpLink({
  uri: `${process.env.REACT_APP_GRAPHQL_URL}`,
  fetch,
});

const authLink: any = setContext((_, { headers }) => {
  const token = getCookie("token");

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});
const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: "no-cache",
    errorPolicy: "ignore",
  },
  query: {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  },
};
const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  defaultOptions: defaultOptions,
});

const fetchQuery = async (query, variables) => {
  try {
    const request = await client.query({
      query,
      variables,
    });
    return request;
  } catch (err: any) {
    Swal.fire({
      icon: "error",
      title: `Something went wrong! ${err?.message}`,
      showConfirmButton: false,
      timer: 3000,
    });
    throw new Error(err);
  }
};

export const createJob = async (jobInput: JobInput) => {
  const createJob = gql`
    mutation createJob($jobInput: JobInput!) {
      createJob(jobInput: $jobInput) {
        id
        title
        uuid
        description
        status
        employerId
        job_role
        experience
        salary
        job_type
        job_level
        job_workingType
        country
        region
      }
    }
  `;
  try {
    const result = await client.mutate({
      mutation: createJob,
      variables: { jobInput },
    });
    return result;
  } catch (err) {
    console.log("jobInput graphql error occurred", JSON.stringify(err));
  }
};

export const jobApplicantStatus = async (applicantInput: ApplicantInput) => {
  const applicantUpdate = gql`
    mutation applicantUpdate($applicantInput: ApplicantInput!) {
      applicantUpdate(applicantInput: $applicantInput) {
        id
        uuid
        # jobId
        # applicantId
        # status
      }
    }
  `;
  try {
    const result = await client.mutate({
      mutation: applicantUpdate,
      variables: { applicantInput },
    });
    return result;
  } catch (err) {
    console.log("jobInput graphql error occurred", JSON.stringify(err));
  }
};

export const createReview = async (reviewInput: ReviewInput) => {
  const createReview = gql`
    mutation createReview($reviewInput: ReviewInput!) {
      createReview(reviewInput: $reviewInput) {
        uuid
        reviews
        ratings
        status
      }
    }
  `;
  try {
    const result = await client.mutate({
      mutation: createReview,
      variables: { reviewInput },
    });
    return result;
  } catch (err) {
    console.log("jobInput graphql error occurred", JSON.stringify(err));
  }
};

export const applyJob = async (
  uuid: string,
  userId: any,
  jobCv: string,
  profileCv: any
) => {
  const appliedJob = gql`
    mutation appliedJob(
      $uuid: String!
      $userId: String!
      $jobCv: String
      $profileCv: Boolean
    ) {
      appliedJob(
        uuid: $uuid
        userId: $userId
        jobCv: $jobCv
        profileCv: $profileCv
      ) {
        id
        title
        uuid
        description
        status
        employerId
        job_role
        experience
        salary
        job_type
        job_level
        job_workingType
        country
        region
      }
    }
  `;
  try {
    const result = await client.mutate({
      mutation: appliedJob,
      variables: { userId, uuid, jobCv, profileCv },
    });
    return result;
  } catch (err) {
    console.log("jobApplied graphql error occurred", JSON.stringify(err));
  }
};

export const sendJobNotification = async (
  jobNotificationInput: JobNotificationInput
) => {
  const createJobNotification = gql`
    mutation createJobNotification(
      $jobNotificationInput: JobNotificationInput
    ) {
      createJobNotification(jobNotificationInput: $jobNotificationInput)
    }
  `;
  try {
    const result = await client.mutate({
      mutation: createJobNotification,
      variables: { jobNotificationInput },
    });
    const message = true;
    result.data.message = message;
    return result;
  } catch (err) {
    console.log(
      "sendJobNotification graphql error occurred",
      JSON.stringify(err)
    );
  }
};

export const getAllJobs = async (
  userId: string | undefined,
  startIndex: number | any,
  endIndex: string | any
) => {
  const query = gql`
    query jobList($userId: String!, $startIndex: ID!, $endIndex: ID!) {
      jobList(userId: $userId, startIndex: $startIndex, endIndex: $endIndex) {
        id
        title
        uuid
        description
        status
        employerId
        job_role
        experience
        salary
        job_type
        job_level
        job_workingType
        country
        region
        organisation
        job_skills
        applicants
        createdAt
        job_start_date
        job_end_date
        city
        qualifications
        benefits
      }
    }
  `;
  const variables = {
    userId,
    startIndex,
    endIndex,
  };

  const res = await fetchQuery(query, variables);
  return res;
};

export const getPublicAllJobs = async (
  startIndex: number | any,
  endIndex: string | any
) => {
  const query = gql`
    query allJobs($startIndex: ID!, $endIndex: ID!) {
      allJobs(startIndex: $startIndex, endIndex: $endIndex) {
        id
        title
        uuid
        description
        status
        employerId
        job_role
        experience
        salary
        job_type
        job_level
        job_workingType
        country
        region
        organisation
        job_skills
        applicants
        createdAt
        job_start_date
        job_end_date
        city
        qualifications
        benefits
      }
    }
  `;
  const variables = {
    startIndex,
    endIndex,
  };

  const res = await fetchQuery(query, variables);
  return res;
};

export const getJobById = async (uuid: string | undefined) => {
  const query = gql`
    query jobById($uuid: ID!) {
      jobById(uuid: $uuid) {
        id
        title
        uuid
        description
        status
        employerId
        job_role
        experience
        salary
        job_type
        job_level
        job_workingType
        country
        region
        organisation
        job_skills
        applicants
        createdAt
        job_start_date
        job_end_date
        city
        qualifications
        benefits
      }
    }
  `;
  const variables = {
    uuid,
  };

  const res = await fetchQuery(query, variables);
  return res;
};

export const getJobStatusById = async (
  uuid: string | undefined,
  userId: string
) => {
  const query = gql`
    query jobAppliedStatus($uuid: ID!, $userId: String!) {
      jobAppliedStatus(uuid: $uuid, userId: $userId) {
        id
        title
        uuid
        description
        status
        employerId
        job_role
        experience
        salary
        job_type
        job_level
        job_workingType
        country
        region
        organisation
        job_skills
        applicants
      }
    }
  `;
  const variables = {
    uuid,
    userId,
  };

  const res = await fetchQuery(query, variables);
  return res;
};

export const getAllApplicants = async (uuid: string | undefined) => {
  const query = gql`
    query jobApplicants($uuid: ID!) {
      jobApplicants(uuid: $uuid) {
        id
        title
        uuid
        description
        status
        employerId
        job_role
        experience
        salary
        job_type
        job_level
        job_workingType
        country
        region
        organisation
        job_skills
        applicants
        isProfileCv
        cv
        firstName
        lastName
        profileCv
        userId
        Contact_number
      }
    }
  `;
  const variables = {
    uuid,
  };

  const res = await fetchQuery(query, variables);
  return res;
};

export const getApplicantsCertificate = async (email: any, jobId: any) => {
  const query = gql`
    query jobApplicantsBadges($email: String!, $jobId: String!) {
      jobApplicantsBadges(userId: $email, jobId: $jobId) {
        id
        score
        status
        assessmentName
        finalPer
        assessmentId
        totalWeightage
        createdAt
        number
        date
      }
    }
  `;

  const variables = {
    email,
    jobId,
  };

  const res = await fetchQuery(query, variables);
  return res;
};

export const getSpecificUserReview = async (
  uuid: string | undefined,
  userId: string
) => {
  const query = gql`
    query reviewByuserId($uuid: ID!, $userId: String!) {
      reviewByuserId(uuid: $uuid, userId: $userId) {
        uuid
        reviews
        ratings
        status
        userId
        image
        firstName
      }
    }
  `;
  const variables = {
    uuid,
    userId,
  };

  const res = await fetchQuery(query, variables);
  return res;
};

export const getAllReviews = async (uuid: string | undefined) => {
  const query = gql`
    query review($uuid: ID!) {
      review(uuid: $uuid) {
        uuid
        reviews
        ratings
        status
        userId
      }
    }
  `;
  const variables = {
    uuid,
  };

  const res = await fetchQuery(query, variables);
  return res;
};

export const reviewDelete = async (
  uuid: string | undefined,
  userId: string
) => {
  const query = gql`
    query reviewDelete($uuid: ID!, $userId: String!) {
      reviewDelete(uuid: $uuid, userId: $userId) {
        uuid
      }
    }
  `;
  const variables = {
    uuid,
    userId,
  };

  const res = await fetchQuery(query, variables);
  return res;
};

export const getJobFilter = async (
  jobType: string | undefined,
  filterType: string | any
) => {
  const query = gql`
    query jobFilter($jobType: String!, $filterType: String!) {
      jobFilter(jobType: $jobType, filterType: $filterType) {
        id
        title
        uuid
        description
        status
        employerId
        job_role
        experience
        salary
        job_type
        job_level
        job_workingType
        country
        region
        organisation
        job_skills
        applicants
        firstName
        lastName
        job_end_date
        job_start_date
        createdAt
      }
    }
  `;
  const variables = {
    jobType,
    filterType,
  };

  const res = await fetchQuery(query, variables);
  return res;
};
