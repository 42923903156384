import React, { useState } from "react";
import { Select, Tooltip, Drawer } from "antd";
import { getJobFilter } from "../../serviceApi/adminApi/JobApi";
import { PrimaryButton } from "../buttons/PrimaryButton";
import { jobRoleData } from "../../constant/job-role";
export default function JobDataFilter({ jobDetails }) {
  const [jobWorkingType, setJobWorkingType] = useState<any>([]);
  const [jobWorkingMode, setJobWorkingMode] = useState<any>([]);
  const [jobLevel, setJobLevel] = useState<any>([]);
  const [jobRole, setJobRole] = useState<any>([]);
  const [jobStatus, setJobStatus] = useState<any>([]);
  const [jobPosted, setJobposted] = useState<any>([]);
  const [open, setOpen] = useState(false);

  const isDisabled =
    jobStatus?.length > 0 ||
    jobRole?.length > 0 ||
    jobWorkingMode?.length > 0 ||
    jobWorkingType?.length > 0 ||
    jobPosted?.length > 0 ||
    jobLevel?.length > 0;

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const handleJobtype = async (jobType) => {
    setJobWorkingType(jobType);
  };

  const handleRoleChange = async (role) => {
    setJobRole(role);
  };

  const handleworkingMode = async (workingType) => {
    setJobWorkingMode(workingType);
  };

  const handleLevel = async (level) => {
    setJobLevel(level);
  };
  const handleStatus = async (level) => {
    setJobStatus(level);
  };
  const handleReset = () => {
    setJobWorkingMode([]);
    setJobWorkingType([]);
    setJobLevel([]);
    setJobRole([]);
    setJobStatus([]);
    setJobposted([]);
  };
  const handleJobposted = async (time) => {
    setJobposted(time);
  };

  const handleClick = async () => {
    var jobs: any = [];
    for (let i in jobWorkingType) {
      const res = await getJobFilter(jobWorkingType[i], "type");
      if (res?.data?.jobFilter?.length > 0) {
        jobs?.splice(i, 1, ...res?.data?.jobFilter);
      }
    }
    for (let i in jobLevel) {
      const res = await getJobFilter(jobLevel[i], "level");
      if (res?.data?.jobFilter?.length > 0) {
        jobs?.splice(i, 1, ...res?.data?.jobFilter);
      }
    }
    for (let i in jobWorkingMode) {
      const res = await getJobFilter(jobWorkingMode[i], "mode");
      if (res?.data?.jobFilter?.length > 0) {
        jobs?.splice(i, 1, ...res?.data?.jobFilter);
      }
    }
    for (let i in jobStatus) {
      const res = await getJobFilter(jobStatus[i], "status");
      if (res?.data?.jobFilter?.length > 0) {
        jobs?.splice(i, 1, ...res?.data?.jobFilter);
      }
    }
    for (let i in jobRole) {
      const res = await getJobFilter(jobRole[i], "role");

      if (res?.data?.jobFilter?.length > 0) {
        jobs?.splice(i, 1, ...res?.data?.jobFilter);
      }
    }

    for (let i in jobPosted) {
      const res = await getJobFilter(jobPosted[i], "jobposted");
      if (res?.data?.jobFilter?.length > 0) {
        jobs?.splice(i, 1, ...res?.data?.jobFilter);
      }
    }

    jobDetails(jobs);
  };

  return (
    <div className="relative">
      <Tooltip title="Filter Job">
        <div className="w-full flex justify-end">
          <button
            onClick={showDrawer}
            className="text-gray-700 focus:outline-none  font-semibold  mt-4 flex gap-x-2 lg:mt-2"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              fill="currentColor"
              className="text-gray-700"
              width={20}
              height={20}
            >
              <path d="M3.9 54.9C10.5 40.9 24.5 32 40 32H472c15.5 0 29.5 8.9 36.1 22.9s4.6 30.5-5.2 42.5L320 320.9V448c0 12.1-6.8 23.2-17.7 28.6s-23.8 4.3-33.5-3l-64-48c-8.1-6-12.8-15.5-12.8-25.6V320.9L9 97.3C-.7 85.4-2.8 68.8 3.9 54.9z" />
            </svg>
            Filter
          </button>
        </div>
      </Tooltip>

      <Drawer title="Filter Jobs by" onClose={onClose} open={open} width={350}>
        <Select
          mode="multiple"
          placeholder="Filter By Job Role"
          value={jobRole}
          onChange={handleRoleChange}
          style={{
            width: "100%",
            borderRadius: "8px",
            marginTop: "10px",
          }}
          options={jobRoleData}
        />
        <Select
          mode="multiple"
          placeholder="Filter By Job Working Type"
          value={jobWorkingType}
          onChange={handleJobtype}
          style={{
            width: "100%",
            borderRadius: "8px",
            marginTop: "10px",
          }}
          options={[
            { value: "Full-Time", label: "Full-Time" },
            { value: "Part-Time", label: "Part-Time" },
            { value: "Contract", label: "Contract" },
          ]}
        />
        <Select
          mode="multiple"
          placeholder="Filter By Job Working Mode"
          value={jobWorkingMode}
          onChange={handleworkingMode}
          style={{
            width: "100%",
            borderRadius: "8px",
            marginTop: "10px",
          }}
          options={[
            { value: "Remote", label: "Remote" },
            { value: "Hybrid", label: "Hybrid" },
            { value: "On-Site", label: "On-Site" },
          ]}
        />
        <Select
          mode="multiple"
          placeholder="Filter By Job Level"
          value={jobLevel}
          onChange={handleLevel}
          style={{
            width: "100%",
            borderRadius: "8px",
            marginTop: "10px",
          }}
          options={[
            { value: "Entry Level", label: "Entry Level" },
            { value: "Junior Level", label: "Junior Level" },
            { value: "Senior Level", label: "Senior Level" },
            { value: "Expert", label: "Expert" },
          ]}
        />
        <Select
          mode="multiple"
          placeholder="Filter By Job Status"
          value={jobStatus}
          onChange={handleStatus}
          style={{
            width: "100%",
            borderRadius: "8px",
            marginTop: "10px",
          }}
          options={[
            { value: "Active", label: "Active" },
            { value: "InActive", label: "InActive" },
          ]}
        />
        <Select
          mode="multiple"
          placeholder="Filter By Job Posted"
          value={jobPosted}
          onChange={handleJobposted}
          style={{
            width: "100%",
            borderRadius: "8px",
            marginTop: "10px",
          }}
          options={[
            { value: "Past 24 hours", label: "Past 24 hours" },
            { value: "Past Week", label: "Past Week" },
            { value: "Past Month", label: "Past Month" },
            { value: "Any Time", label: "Any Time" },
          ]}
        />

        <div className="flex  gap-[10px] justify-center mt-8">
          <PrimaryButton
            text="Reset"
            onclick={handleReset}
            className={`${
              isDisabled
                ? "bg-black hover:bg-transparent hover:border-black border-black hover:text-black cursor-pointer"
                : "bg-gray-300 cursor-not-allowed border-gray-300"
            }`}
          />
          <PrimaryButton
            text="Apply"
            onclick={handleClick}
            className={`${
              isDisabled
                ? "bg-pink-500 hover:bg-transparent hover:border-pink-500 border-pink-500 hover:text-pink-500 cursor-pointer"
                : "bg-gray-300 cursor-not-allowed border-gray-300"
            }`}
          />
        </div>
      </Drawer>
    </div>
  );
}
