import React, { useState, useEffect } from "react";
import UserIcon from "../../../assets/leftnavicons/UserIcon.svg";

const SpeakersSection = ({ speakers }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 640); // sm breakpoint in Tailwind
    };
    handleResize(); // Set on initial load
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % speakers?.length);
    }, 4000);
    return () => clearInterval(interval);
  }, [speakers.length]);

  return (
    <div>
      {isMobile && (
        <div className="overflow-hidden relative h-full">
          <div
            className="transition-transform duration-700 ease-in-out grid grid-cols-1 gap-6 p-6"
            style={{
              transform: `translateY(-${currentSlide * 25}%)`,
            }}
          >
            {speakers.map((speaker, index) => (
              <div
                key={index}
                className="flex flex-col items-center bg-white shadow-md rounded-lg p-4 w-full h-full justify-center transform transition-transform hover:scale-105 hover:shadow-lg hover:z-10"
              >
                <img
                  src={
                    speaker?.image
                      ? `${process.env.REACT_APP_MEDIA_URL}/api/media/download${speaker?.image}`
                      : UserIcon
                  }
                  alt={speaker?.speakerName}
                  className="w-24 h-24 rounded-full object-cover mb-4 border-gray-500 border-x-black shadow-xl"
                />
                <h3 className="text-lg font-bold text-gray-800 text-center">
                  {speaker?.speakerName}
                </h3>
                <p
                  className="text-sm text-black truncate overflow-wrap: break-word overflow-para word-wrap: break-word break-words overflow-wrap break-word  w-full text-wrap text-center"
                  
                  dangerouslySetInnerHTML={{
                    __html: speaker?.speakerDescription,
                  }}
                ></p>
              </div>
            ))}
          </div>
        </div>
      )}

      {!isMobile && (
        <div className="overflow-hidden relative h-full">
          {/* Slider Container */}
          <div
            className="transition-transform duration-700 ease-in-out grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-4 gap-6 p-6"
            style={{
              transform: `translateX(-${currentSlide * 25}%)`,
            }}
          >
            {speakers.map((speaker, index) => (
              <div
                key={index}
                className="flex flex-col items-center bg-white shadow-md rounded-lg p-4 mx-2 w-full min-w-[calc(100%/3)] sm:min-w-[calc(100%/4)] transform transition-transform hover:scale-105 hover:shadow-lg hover:z-10"
              >
                <img
                  src={
                    speaker?.image
                      ? `${process.env.REACT_APP_MEDIA_URL}/api/media/download${speaker?.image}`
                      : UserIcon
                  }
                  alt={speaker?.speakerName}
                  className="w-24 h-24 rounded-full object-cover mb-4 border-gray-500 border-x-black shadow-xl"
                />
                <h3 className="text-lg font-bold text-gray-800 text-center">
                  {speaker?.speakerName}
                </h3>
                <p
                  className="text-sm text-black truncate overflow-wrap: break-word overflow-para word-wrap: break-word break-words overflow-wrap break-word  w-full text-wrap text-center"
                  dangerouslySetInnerHTML={{
                    __html: speaker?.speakerDescription,
                  }}
                ></p>
              </div>
            ))}
          </div>

          {/* Navigation Dots */}
          <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 flex gap-2">
            {speakers.map((_, index) => (
              <button
                key={index}
                onClick={() => setCurrentSlide(index)}
                className={`w-3 h-3 rounded-full ${
                  currentSlide === index ? "bg-blue-500" : "bg-gray-300"
                }`}
              ></button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default SpeakersSection;
