import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Modal, Tooltip, message } from "antd";
import SocialShare from "../../../../components/socialShare/SocialShare";
import { CountdownTimer } from "../assesments/questions/CountdownTimer";
export const EventCard = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Define the target date you want to calculate remaining days for
  let data = new Date(Number(props?.item?.event_start_date)).toUTCString();
  let finalDate = new Date(data.split("GMT")[0]).getTime();

  let utcEndDate = new Date(Number(props?.item?.event_end_date)).toUTCString();
  let endDate = new Date(utcEndDate.split("GMT")[0]).getTime();

  let todayDate = Date.now();

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const getTimeExpireStatus = (data: number) => {
    if (data <= 0) {
      // submitOnReload(language);
    }
  };

  return (
    <>
      <section className="border h-full relative" key={props?.item?.uuid}>
        {todayDate > endDate ? (
          <>
            <div className=" absolute z-[80] opacity-90  bg-white top-0 bottom-0 left-0 right-0  inset-0 flex justify-center items-center border-md ">
              <div className="p-0 flex space-y-4 flex-col justify-center items-center text-lg text-black text-center font-primary font-bold ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-14 h-14 text-brand-500"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M16.5 10.5V6.75a4.5 4.5 0 1 0-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H6.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z"
                  />
                </svg>

                <p>Event-Expired</p>
              </div>
            </div>
          </>
        ) : todayDate <= finalDate ? (
          <>
            <div className="relative z-[80] inset-0 ">
              <div className="p-0 flex space-y-4 flex-col justify-center items-center text-lg text-black text-center font-primary font-bold absolute right-0">
                <CountdownTimer
                  expireTimestatus={getTimeExpireStatus}
                  targetDate={finalDate}
                  type="event"
                />
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="relative z-[80] inset-0 ">
              <div className="p-0 flex space-y-4 flex-col justify-center items-center text-lg text-black text-center font-primary font-bold absolute right-0">
                <p className="bg-green-500 text-white p-3 rounded-xl m-2">
                  Live
                </p>
              </div>
            </div>
          </>
        )}
        <div className="group hover:shadow-2xl hover:bg-gray-50 transition-all	 duration-500 bg-white h-full border p-3  shadow-lg rounded-xl">
          <Link
            data-testid="assessmentStudent"
            to={props.route}
            className="z-0 relative"
          >
            <div className="h-44 overflow-hidden group-hover:rounded-lg">
              <img
                className="h-full group-hover:scale-125 group-hover:rounded-lg transition-all	 duration-500 w-full object-cover rounded-lg object-center p-3"
                src={
                  props?.item?.image &&
                  `${process.env.REACT_APP_MEDIA_URL}/api/media/download${props?.item?.image}`
                }
                alt="asessment-cover-image"
              />
            </div>

            <div className="relative z-0">
              <h1 className="overflow-para word-wrap: break-word; text-xl font-semibold transition-all	 duration-500 group-hover:text-brand-500 text-black font-primary pt-2 pb-0">
                {props?.item?.name}
              </h1>

              <div
                title={props?.item?.description?.replace(/(<([^>]+)>)/g, "")}
                className="overflow-para text-sm text-gray-600 font-primary pb-2 truncated"
                dangerouslySetInnerHTML={{
                  __html: props?.item?.description,
                }}
              ></div>
              <p className="overflow-para  pb-2 my-0 py-0 text-base font-semibold text-black font-primary">
                Start Date :{" "}
                {new Date(Number(props?.item?.event_start_date)).toUTCString()}
              </p>
            </div>
          </Link>
          <Modal
            title="Share"
            centered
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            okButtonProps={{ disabled: true }}
            cancelButtonProps={{ disabled: true }}
          >
            <SocialShare shareUrl={props?.route} />
          </Modal>
          <div className="flex justify-center items-center bottom-3 right-4 absolute z-50 gap-2">
            {props.url && (
              <p className="my-0 w-full py-0 text-xs  text-gray-500 font-primary border-t pt-3">
                <a
                  href={props.url}
                  className="flex flex-row items-center"
                  target="_blank"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={2}
                    stroke="currentColor"
                    className="w-5 h-5 mr-1"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 21a9.004 9.004 0 008.716-6.747M12 21a9.004 9.004 0 01-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 017.843 4.582M12 3a8.997 8.997 0 00-7.843 4.582m15.686 0A11.953 11.953 0 0112 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0121 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0112 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 013 12c0-1.605.42-3.113 1.157-4.418"
                    />
                  </svg>
                  {props.url}
                </a>
              </p>
            )}
          </div>
        </div>
      </section>
    </>
  );
};
