import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Button,
  IconButton,
} from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";

import InfiniteScroll from "../../../components/infinitescroller/InfiniteScoll";
import { getPublicAllJobs } from "../../../serviceApi/adminApi/JobApi";
import JobDataFilter from "../../../components/filter/JobDataFilter";
import JobCard from "../../../components/card/JobCard";
import { BreadcrumbComp } from "../../../components/Breadcrumb/Breadcrumb";
import { useNavigate } from "react-router-dom";
import { DataLoader } from "../../../components/loader/DataLoader";
import PublicHeader from "./PublicHeader";
import GoBackbutton from "../../../components/buttons/GoBackbutton";
import { loginContext } from "../../../components/context/LoginContext";
export default function JobsPage() {
  const { loginWithRedirect } = useAuth0();
  const [id, setId] = useState("");
  const [selectedJob, setSelectedJob] = React.useState<any>([]);
  const [data, setData] = useState<any>([]);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [jobSkills, setJobSkills] = useState([]);
  const [jobQualifications, setJobQualifications] = useState([]);
  const [jobBenefits, setJobBenefits] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [status, setStatus] = useState("");

  const fetchData = async (currentPage) => {
    const startIndex = (currentPage - 1) * 4;
    const endIndex = 4;
    setTimeout(async () => {
      const res = await getPublicAllJobs(startIndex, endIndex);
      if (res?.data?.allJobs?.length > 0) {
        setIsLoading(false);
        setData([...data, ...res?.data?.allJobs]);
        setPage(currentPage + 1);
        setSelectedJob(res?.data?.allJobs[0]);
        setId(res?.data?.allJobs[0]?.uuid);
        setJobSkills(JSON?.parse(res?.data?.allJobs[0]?.job_skills));
        setJobQualifications(
          JSON?.parse(res?.data?.allJobs[0]?.qualifications)
        );
        setStatus(res?.data?.allJobs[0]?.status);
        setJobBenefits(JSON?.parse(res?.data?.allJobs[0]?.benefits));
      } else {
        setHasMore(false);
        setIsLoading(false);
      }
    }, 500);
  };

  useEffect(() => {
    if (data?.length > 0) {
      setSelectedJob(data[0]);
    } else {
      setSelectedJob([]);
    }
  }, [data]);

  useEffect(() => {
    return () => {
      cleanUp();
    };
  }, []);

  const cleanUp = () => {
    setSelectedJob([]);
  };
  const handleJobSelect = async (job) => {
    setSelectedJob(job);
    setId(job?.uuid);
    setJobSkills(JSON?.parse(job?.job_skills));
    setJobQualifications(JSON?.parse(job?.qualifications));
    setJobBenefits(JSON?.parse(job?.benefits));
    setStatus(job.status);
  };

  const renderData = () => {
    return isLoading ? (
      ""
    ) : data?.length > 0 ? (
      data?.map((job, i) => (
        <JobCard
          key={i}
          id={i}
          job={job}
          role=""
          onclick={() => handleJobSelect(job)}
        />
      ))
    ) : (
      <div className="shadow-md flex  flex-row items-center justify-center h-32 w-60 border-gray-300 rounded-md">
        <h1 className="font-justify-center items-center text-black text-sm">
          No Jobs Found check back later
        </h1>
      </div>
    );
  };

  const breadcrumb = [
    {
      text: "All Jobs",
    },
  ];

  return (
    <>
      <div>
        <PublicHeader />
      </div>
      <div className="max-h-[85vh] font-primary text-black lg:p-10 p-5">
        <p className="text-2xl mb-0 pb-0 font-primary font-medium text-gray-800">
          All Jobs
        </p>
        <div className="sticky top-5 z-20 border-b">
          <div className="mb-4">
            <BreadcrumbComp breadcrumbData={breadcrumb} />
          </div>
          {/* filer added */}
          {data?.length > 0 && (
            <div className="mb-5 flex flex-row items-center justify-between gap-x-4 ml-auto lg:w-80 w-full">
              <div className="sticky top-5 z-20">
                <JobDataFilter jobDetails={setData} />
              </div>
            </div>
          )}
        </div>
        <Box
          sx={{
            display: "flex",
            position: "fixed",
            height: "70vh",
            overflowY: "auto",
            width: "100%",
          }}
        >
          <InfiniteScroll
            fetchData={fetchData}
            renderData={renderData}
            hasMore={hasMore}
            page={page}
          />

          {selectedJob && data?.length > 0 ? (
            <Box
              sx={{ width: "60%", padding: 2, overflowY: "auto" }}
              key={selectedJob?.id}
            >
              <div key={selectedJob?.id}>
                <Card key={selectedJob?.id}>
                  <CardContent>
                    <Typography variant="h5">{selectedJob?.title}</Typography>
                    <Typography color="textPrimary">
                      {selectedJob?.organisation}
                    </Typography>
                    <Typography color="textPrimary">
                      {selectedJob?.country},{selectedJob?.region}
                    </Typography>
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{ mt: 2 }}
                      onClick={() => loginWithRedirect({})}
                    >
                      Apply
                    </Button>
                    <Box sx={{ mt: 2, display: "flex", alignItems: "center" }}>
                      <Typography color="textPrimary" component={"p"}>
                        {selectedJob?.job_level}
                      </Typography>
                      <Typography color="textPrimary" sx={{ mx: 1 }}>
                        •
                      </Typography>
                      <Typography color="textPrimary">
                        {selectedJob?.job_type}
                      </Typography>
                      <Typography color="textPrimary" sx={{ mx: 1 }}>
                        •
                      </Typography>
                      <Typography color="textPrimary" component={"p"}>
                        {selectedJob.job_workingType}
                      </Typography>
                    </Box>
                    <Typography variant="h6" sx={{ mt: 3 }} component={"p"}>
                      Job Highlights
                    </Typography>
                    <Typography variant="body1" sx={{ mt: 1 }} component={"p"}>
                      Job Skills
                    </Typography>
                    {jobSkills.map((skill, i) => {
                      return (
                        <Typography variant="body2" color="textPrimary" key={i} component={"p"}>
                          • {skill}
                          <br />
                        </Typography>
                      );
                    })}
                    <Typography variant="body1" sx={{ mt: 1 }} component={"p"}>
                      Qualifications
                    </Typography>

                    {jobQualifications?.map((qualification, i) => {
                      return (
                        <Typography
                          variant="body2"
                          color="textPrimary"
                          className="capitalize"
                          key={i}
                          component={"p"}
                        >
                          • {qualification} or equavailent
                          <br />
                        </Typography>
                      );
                    })}

                    <Typography variant="body1" sx={{ mt: 2 }} component={"p"}>
                      Benefits
                    </Typography>
                    <Typography variant="body2" color="textPrimary" component={"p"}>
                      • The {selectedJob?.country} base salary range for this{" "}
                      {selectedJob?.job_type} position is {selectedJob?.salary}
                      <br />• Our salary ranges are determined by role, level,
                      and location.
                    </Typography>
                    {jobBenefits?.map((benefit, i) => {
                      return (
                        <Typography
                          variant="body2"
                          color="textPrimary"
                          className="capitalize"
                          key={i}
                          component={"p"}
                        >
                          • {benefit}
                          <br />
                        </Typography>
                      );
                    })}
                    <Typography variant="body1" sx={{ mt: 2 }} component={"p"}>
                      Job Description
                    </Typography>
                    <Typography variant="body2" color="textPrimary" component={"div"}>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: selectedJob?.description,
                        }}
                      ></div>
                    </Typography>
                  </CardContent>
                </Card>
              </div>
            </Box>
          ) : (
            isLoading && (
              <div className="flex justify-center">
                <DataLoader />
              </div>
            )
          )}
        </Box>
        <GoBackbutton />
      </div>
    </>
  );
}
