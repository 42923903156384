import { Drawer } from "antd";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { LoginButton } from "../buttons/LoginButton";

export default function PublicDrawer() {
  const { loginWithRedirect } = useAuth0();
  const [open, setOpen] = useState<boolean>(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <button
        type="button"
        className="inline-flex items-center justify-center sm:hidden lg:hidden p-1 rounded-md text-white  focus:outline-none ring-2 ring-inset ring-white"
        aria-controls="mobile-menu"
        aria-expanded="false"
        onClick={showDrawer}
      >
        <svg
          className=" h-6 w-6"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M4 6h16M4 12h16M4 18h16"
          />
        </svg>
      </button>
      <Drawer
        placement="right"
        onClose={onClose}
        open={open}
        width={200}
        onMouseLeave={onClose}
      >
        {/* public header options */}
        <ul className="mt-0 pt-0">
          <li className="sm:text-sm font-primary text-black my-1 font-sans text-sm border-b p-1 border-gray-500 hover:bg-white hover:text-black ">
            <a
              href="https://skillcef.com"
              className="text-black hover:text-white"
            >
              Home
            </a>
          </li>
          <Link to="/allevents" className="text-white hover:text-white">
            <li className="sm:text-sm font-primary text-black my-1 font-sans text-sm border-b p-1 border-gray-500 hover:bg-white hover:text-black">Events</li>
          </Link>
          <Link to="/alljobs" className="text-white hover:text-white">
            <li className="sm:text-sm font-primary text-black my-1 font-sans text-sm border-b p-1 border-gray-500 hover:bg-white hover:text-black">Jobs</li>
          </Link>
          <Link to="/mock-assessments" className="text-white hover:text-white">
            <li className="sm:text-sm font-primary text-black my-1 font-sans text-sm border-b p-1 border-gray-500 hover:bg-white hover:text-black">Practice</li>
          </Link>
          <li className="sm:text-sm font-primary text-black my-1 font-sans text-sm border-b p-1 border-gray-500 hover:bg-white hover:text-black">
            <a
              href="https://skillcef.com/blog/"
              className="text-black hover:text-white"
            >
              Blogs
            </a>
          </li>
          <li onClick={() => loginWithRedirect({})}>
            <LoginButton />
          </li>
        </ul>
      </Drawer>
    </div>
  );
}
