import React, { useContext, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router";
import { getUsers } from "../../serviceApi/api";
import { deleteCookieToken, getCookie, setCookie } from "../../utils";
import { loginContext } from "../context/LoginContext";
import Swal from "sweetalert2";
import { eventRegistration } from "../../serviceApi/adminApi/EventApi";
import { DataLoader } from "./DataLoader";
export const LoginLoader = () => {
  const navigate = useNavigate();
  const { user, logout, getAccessTokenSilently } = useAuth0();
  const email: string | undefined = user?.email;
  const { role, setRole } = useContext<any>(loginContext);
  if (email === undefined || email === null || email === "") {
    navigate("/");
  }

  const eventId = getCookie("eventId");
  const name: any = localStorage.getItem("name");

  const handleEventRegistration = async () => {
    const data = {
      email: email,
      name: name,
      eventId: eventId,
      userName:
        `${user?.nickname?.charAt(0).toUpperCase()}` +
        `${user?.nickname?.slice(1)}`,
    };

    const event = await eventRegistration(
      data.email,
      data.name,
      data.eventId,
      data.userName
    );
    if (event?.data) {
      Swal.fire({
        title: `${event?.data?.eventRegister?.message}`,
        icon: "success",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Continue",
      }).then((result) => {
        if (result.isConfirmed) {
          window.localStorage.removeItem("@@Auth");
          deleteCookieToken("token");
          deleteCookieToken("eventId");
          localStorage.removeItem("name");
          logout({});
        } else {
          window.localStorage.removeItem("@@Auth");
          deleteCookieToken("token");
          deleteCookieToken("eventId");
          localStorage.removeItem("name");
          logout({});
        }
      });
    } else {
      Swal.fire({
        icon: "error",
        title: `Something went wrong`,
        showConfirmButton: false,
        timer: 1500,
      });
      window.localStorage.removeItem("@@Auth");
      deleteCookieToken("token");
      deleteCookieToken("eventId");
      localStorage.removeItem("name");
      logout({});
    }
  };

  useEffect(() => {
    if (eventId) {
      handleEventRegistration();
    }
  }, [eventId]);
  const validateUserRole = async (email) => {
    const userRole = await getUsers(email);
    if (userRole?.data?.user) {
      setRole(userRole?.data?.user[0]?.role);
    } else setRole("new user");
  };

  const getToken = async () => {
    const token: string = await getAccessTokenSilently({
      authorizationParams: {
        audience: `https://${process.env.REACT_APP_AUTH_DOMAIN}/api/v2/`,
        scope: "read:current_user,offline_access",
      },
    });

    window?.localStorage?.setItem("@@Auth", token);
    setCookie({ token }, 1800);
    if (!token) {
      window.localStorage.removeItem("LoginToken");
      window.localStorage.removeItem("role");
      logout({});
      deleteCookieToken("token");
    }
  };

  useEffect(() => {
    if ((email && email != "") || email != null) {
      validateUserRole(email);
      getToken();
    }
  }, [email, user]);

  useEffect(() => {
    if (eventId) {
    } else {
      if (role === "SuperAdmin") {
        navigate("/home/dashboard");
      } else if (role === "Admin") {
        navigate("/subadmin/AllDomains/list");
      } else if (role === "Student") {
        navigate("/student/dashboard");
      } else if (role === "new user") {
        navigate("/profile");
      }
    }
  }, [role]);

  return (
    <>
      {eventId ? (
        <DataLoader />
      ) : (
        <div className="text-center mx-auto   py-48 lg:py-96 text-5xl ">
          {/* <div className="border-4 flex items-center  animate-bounce mx-auto text-center rounded-full border-green-500 w-5 h-5 p-5"></div> */}
          <img
            className="mx-auto"
            src="https://miro.medium.com/max/640/1*em5HcTFZIQw90qIgdbYjVg.gif"
          />
          <div className="animate-pulse">
            Please wait while we are loading your profile....
          </div>{" "}
        </div>
      )}
    </>
  );
};
