import { Link, useNavigate } from "react-router-dom";
import { Tabs, Breadcrumb, TabsProps, Tooltip, Popconfirm } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { InfoCircleOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { BreadcrumbComp } from "../../../../components/Breadcrumb/Breadcrumb";
import { CreateEvent } from "./CreateEvent";
import {
  deleteEvent,
  getAdminEvents,
} from "../../../../serviceApi/adminApi/EventApi";
import { EditButton } from "../../../../components/buttons/EditButton";
import { loginContext } from "../../../../components/context/LoginContext";
import { useAuth0 } from "@auth0/auth0-react";
import { getCookie } from "../../../../utils";
import axios, { AxiosResponse } from "axios";
import Swal from "sweetalert2";
import PaginationData from "../../../../components/pagination/Pagination";
interface DeleteResponse {
  status: boolean;
  message: string;
}
function AdminEvents() {
  const { user } = useAuth0();
  const email: any = user?.email;
  const { eventId, setEventId }: any = useContext(loginContext);
  const [eventData, setEventData] = useState<any>();
  const { editEnable, setEditEnable }: any = useContext(loginContext);
  const [paginatedData, setPaginatedData] = useState([]);
  const [key, setKey] = useState("1");

  const getEventData = async () => {
    const data = await getAdminEvents(email);
    setEventData(data?.data?.getAdminEvents);
  };

  const onChange = (key: string) => {
    setKey(key);
  };

  const deleteEvents = async (eventId, image) => {
    const formData = new FormData();
    formData.append("image", image);
    formData.append("uuid", eventId);
    formData.append("type", "event");
    const { data }: AxiosResponse<DeleteResponse> = await axios.post(
      `${process.env.REACT_APP_MEDIA_URL}/api/media/delete`,
      formData,
      {
        headers: {
          enctype: "multipart/form-data",
          Authorization: `Bearer ${getCookie("token")}`,
        },
      }
    );
    if (data?.status == true) {
      Swal.fire({
        icon: "success",
        title: "Event Deleted Successfully",
        showConfirmButton: false,
        timer: 1500,
      });
    }
    const res = await deleteEvent(eventId);
    if (res) {
      getEventData();
    }
  };

  useEffect(() => {
    getEventData();
    setEventId(null);
  }, [key]);

  const accountEditText = (
    <div className="flex group hover:text-blue-400 items-center flex-row">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="w-5 h-5  mr-1 group focus:text-blue-400"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
        />
      </svg>
      Create Events
    </div>
  );

  const basicInfotab = (
    <div className="flex group hover:text-blue-400 items-center flex-row">
      <span className="w-5 h-5 -mt-1  mr-1 group focus:text-blue-400">
        <InfoCircleOutlined />
      </span>
      My Events
    </div>
  );

  const basicInfo = (
    <>
      <div className="grid grid-cols-2 lg:grid-cols-4 gap-5">
        {paginatedData?.length > 0 ? (
          paginatedData?.map((item: any, index: number) => {
            return (
              <div
                key={index}
                className="border p-2 w-full bg- mb-5 flex flex-col lg:flex-col space-x-5 h-full mx-auto  shadow-lg rounded-xl"
              >
                <div className="flex flex-col justify-between">
                  <Link to={`/subadmin/admin/event/${item.uuid}`}>
                    <div data-testid="test" className="h-40">
                      <img
                        className="h-full rounded-xl w-full object-center object-cover"
                        src={`${process.env.REACT_APP_MEDIA_URL}/api/media/download${item.image}`}
                        alt={item.image}
                      />
                    </div>
                  </Link>
                  <div className="flex flex-col h-full justify-between">
                    <Link to={`/subadmin/admin/event/${item.uuid}`}>
                      <div className="flex flex-col h-full">
                        <p
                          data-testid="domainTitleId"
                          className="overflow-para p-2 word-wrap: break-word; lg:text-lg md:text:lg  overflow-wrap: break-word	text-wrap: wrap text-base font-primary font-medium text-left capitalize text-gray-900 lg:pt-5 m-0"
                        >
                          {item.name}
                        </p>

                        <div
                          className="overflow-para text-sm text-gray-600 font-primary pb-2 truncated max-w-full h-9"
                          title={item?.description?.replace(/(<([^>]+)>)/g, "")}
                          dangerouslySetInnerHTML={{
                            __html: item?.description,
                          }}
                          style={{ overflowWrap: "break-word" }}
                        ></div>
                      </div>
                    </Link>

                    <div className="border-t border-brand-300 pt-5 items-center mx-2">
                      <div className="flex flex-row justify-end space-x-2 px-2">
                        <div data-testid={`edit ${item.name}`}>
                          <EditButton
                            editRoute={
                              "/subadmin/admin/edit-event/" + item.uuid
                            }
                          />
                        </div>
                        <div data-testid={`delete ${item.name}`}>
                          <>
                            <Popconfirm
                              title={`Are you sure to  delete this Event `}
                              onConfirm={() =>
                                deleteEvents(item?.uuid, item?.image)
                              }
                              okButtonProps={{ loading: false }}
                              icon={
                                <QuestionCircleOutlined
                                  style={{ color: "red" }}
                                />
                              }
                            >
                              <div className="bg-red-500 w-8 h-8 cursor-pointer flex items-center justify-center rounded-lg">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth={1.5}
                                  stroke="currentColor"
                                  className="w-5 h-5 text-white"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                                  />
                                </svg>
                              </div>
                            </Popconfirm>
                          </>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div className="mb-10  flex flex-col lg:flex-col lg:space-x-5 h-full mx-auto bg-white">
            No Events created
          </div>
        )}
      </div>

      {paginatedData && true && (
        <div
          className={`${
            eventData?.length >= 8
              ? paginatedData?.length >= 5
                ? "mt-5"
                : "left-0 right-0 mt-5"
              : "mx-auto hidden"
          }`}
        >
          <PaginationData
            data={eventData}
            paginatedData={setPaginatedData}
            userId={email}
            type="event"
            domainId=""
          />
        </div>
      )}
    </>
  );
  const Account = (
    <div className="mb-10  flex flex-col lg:flex-col lg:space-x-5 h-full mx-auto bg-white">
      <CreateEvent defaultKey={setKey} />
    </div>
  );

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: basicInfotab,
      children: basicInfo,
    },
    {
      key: "2",
      label: accountEditText,
      children: Account,
    },
  ];

  const breadcrumbData = [
    {
      path: "/subadmin/AllDomains/list",
      text: "Dashboard",
    },

    { text: "My Events" },
  ];

  return (
    <section className="w-full max-h-[86vh] overflow-y-auto lg:p-5 px-4">
      <div className="lg:my-4 my-2 lg:sticky">
        <p className="lg:text-2xl text-lg mb-0 pb-0 font-primary font-semibold text-gray-800">
          Event/Workshop
        </p>

        <div className="my-2">
          <BreadcrumbComp breadcrumbData={breadcrumbData} />
        </div>
      </div>
      <div className="  bg-white rounded-xl">
        <div className="w-full px-3  lg:px-10">
          <Tabs
            className="profile-tabs"
            defaultActiveKey={key}
            size="large"
            items={items}
            activeKey={key}
            onChange={onChange}
          />
        </div>
      </div>
    </section>
  );
}

export default AdminEvents;
