import React from "react";

export const InputField = (props) => {
  function emailValid(inputText) {
    const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (mailformat.test(inputText)) {
      return true;
    }
  }

  function containsSpecialChars(str: string) {
    const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    // const specialChars=/^[a-zA-Z]+$/;
    return specialChars.test(str);
  }
  function containsString(str: string) {
    // const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    const specialChars = /^[a-zA-Z ]+$/;
    return specialChars.test(str);
  }

  const checkValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    props.onChange(e.target.value);
    if (props?.type === "text") {
      if (props?.id === "eventName"||props?.id==="jobTitle") {
        if (value?.trim().length <= 100 && value?.trim().length >= 2) {
          props?.containsSpecialCharsMsg("");
        } else
          props?.containsSpecialCharsMsg(
            "Text should be between 2 to 100 characters"
          );
      } else {
        if (value?.trim().length <= 60 && value?.trim().length >= 2) {
          if (!containsSpecialChars(value)) {
            props?.containsSpecialCharsMsg("");
          } else {
            props?.containsSpecialCharsMsg("Please remove special character");
          }
        } else
          props?.containsSpecialCharsMsg(
            "Text should be between 2 to 60 characters"
          );
      }
    } else if (props?.id == "Weightage" && props?.type == "number") {
      if (Number(value) > 10 || Number(value) <= 0) {
        props?.containsWeightageMsg("Weightage should be between 1 to 10");
      } else {
        props?.containsWeightageMsg("");
      }
    } else if (props?.id == "Topic Name" && props?.type == "topic") {
      if (value?.trim().length <= 60 && value?.trim().length >= 2) {
        if (containsString(value)) {
          props?.containsSpecialCharsMsg("");
        } else {
          props?.containsSpecialCharsMsg(
            "Please enter valid topic name e.g function ,loops"
          );
        }
      } else
        props?.containsSpecialCharsMsg(
          "Text should be between 2 to 60 characters"
        );
    } else if (
      props?.id == "Assessment duration in minutes" &&
      props?.type == "number"
    ) {
      if (Number(value) > 180 || Number(value) <= 0)
        props?.containsWeightageMsg("Duration should be between 1 to 180");
      else {
        props?.containsWeightageMsg("");
      }
    } else if (props?.id == "mobile number" && props?.type == "number") {
      if (value?.length > 10 || value?.length < 10) {
        props?.mobileNumberValid("Mobile number should be 10 digit");
      } else {
        props?.mobileNumberValid("");
      }
    } else if (props?.id == "D.O.B" && props?.type == "date") {
      if (value) {
        const time: Date = new Date();
        if (new Date(value) < time) {
          const mondDiff: number = time.getTime() - new Date(value)?.getTime();
          const month: number = new Date(value).getMonth() + 1;
          if (month == 2) {
            const day = new Date(value).getDate();
            if (day <= 28) {
              props?.checkValidDate("");
            } else if (day == 29) {
              const year = new Date(value).getUTCFullYear();
              if (year % 4 == 0 || year % 100 == 0) {
                props?.checkValidDate("");
              } else {
                props?.checkValidDate("Please enter valid date");
              }
            } else {
              props?.checkValidDate("Please enter valid date");
            }
          }
          const age_dt = new Date(mondDiff);
          const year = age_dt.getUTCFullYear();
          const age = Math.abs(year - 1970);
          props?.checkValidDate("");
          if (age > 5) {
            props?.checkValidDate("");
          } else {
            props?.checkValidDate("DOB should be above 5 years");
          }
        } else {
          props?.checkValidDate("DOB should be below current date");
        }
      } else {
        props?.checkValidDate("DOB required valid date");
      }
    } else if (props?.id == "Email Id" && props?.type == "email") {
      if (emailValid(value)) {
        props?.emailValidCheck("");
      } else {
        props?.emailValidCheck("Please enter valid email");
      }
    }
  };

  return (
    <div className={`${props?.userProfile ? "lg:mt-2" : "lg:mt-2"}`}>
      <label
        className="font-primary capitalize text-sm lg:text-base"
        htmlFor={props?.id}
      >
        {props?.id}{" "}
        <span
          className={`${
            props?.id === "Website URL" ? "hidden" : "text-red-600"
          }`}
        >
          *
        </span>
      </label>
      <input
        id={props?.id}
        type={props?.type}
        name={props?.name}
        disabled={props?.disabledStatus ? true : false}
        placeholder={props?.placeholder}
        value={props?.value}
        pattern={props?.pattern}
        onChange={(e) => checkValue(e)}
        className={`${
          props?.userProfile ? `lg:p-2.5 p-2.5` : `lg:p-3.5 p-3.5`
        } text-sm lg:mt-2 border border-gray-300 ${
          props?.disabledStatus ? "cursor-not-allowed" : "cursor-text"
        }  focus:outline-none w-full rounded-lg`}
        readOnly={props?.readOnly}
        autoComplete="off"
      />
    </div>
  );
};
