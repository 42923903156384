import React from "react";
import { Link } from "react-router-dom";
import { PopupModel } from "../../views/authenticateViews/SuperAdmin/popupModel";
import { DeleteButton } from "../buttons/DeleteButton";
import { EditButton } from "../buttons/EditButton";
import { ActivateButton } from "../buttons/ActivateButton";

export const DomainCard = (props) => {
  const [status, setStatus] = React.useState(false);
  const modalStatus = (data) => {
    setStatus(data);
  };

  return (
    <div className="p-2 border  flex flex-col justify-between rounded-xl h-full w-full  bg-white shadow-xl relative">
      {/* blur */}
      {props?.route || props.status != "Approved" ? (
        ""
      ) : (
        <div className="absolute top-0 inset-0 bottom-0 left-0 right-0 bg-gray-300 rounded-xl bg-opacity-60 cursor-not-allowed"></div>
      )}
      {props.role === "Admin" && props.status != "Approved" ? (
        <>
          <div className="bg-black  opacity-40 absolute top-0 bottom-0 left-0 right-0 rounded-lg"></div>
          <div className="absolute inset-0  flex items-center justify-center">
            <ActivateButton
              reqStatus={props.reqStatus}
              status={props.status}
              uuid={props.uuid}
              name={props?.title}
              username={props?.username}
            />
          </div>
        </>
      ) : (
        ""
      )}

      {status ? (
        <PopupModel
          status={status}
          modalStatus={modalStatus}
          title={`Domain Name: - ${props.title} `}
        />
      ) : (
        ""
      )}

      <div className="flex flex-col justify-between">
        {props?.route ? (
          <Link to={props?.route ? props?.route : ""}>
            <div data-testid={props.title} className={`${props?.CardHeight}`}>
              {props.image ? (
                <img
                  className="h-full rounded-xl w-full object-center object-cover"
                  src={
                    props.image
                      ? `${process.env.REACT_APP_MEDIA_URL}/api/media/download${props?.image}`
                      : ""
                  }
                  alt={props.title}
                />
              ) : (
                <img
                  className="h-full rounded-xl w-full object-center object-contain"
                  src={
                    props.image
                      ? `${process.env.REACT_APP_MEDIA_URL}/api/media/download${props?.image}`
                      : ""
                  }
                  alt={props.title}
                />
              )}
            </div>
            <div>
              <p
                data-testid="domainTitleId"
                className="overflow-para word-wrap: break-word; lg:text-lg md:text:lg  overflow-wrap: break-word	text-wrap: wrap text-base font-primary font-medium text-left capitalize text-gray-900 py-2 lg:pt-5  m-0"
              >
                {props.title}
              </p>
              {props.role === "Admin" && (
                <p className="text-green-600 font-primary font-medium text-base">
                  <span className="text-black">Status:-</span>{" "}
                  {props.status === "Approved" ? (
                    <span className="text-green-600">Approved</span>
                  ) : props.status === "Denied" ? (
                    <span className="text-red-600 font-primary font-medium text-base">
                      Denied please retry
                    </span>
                  ) : props.status === "Pending" ? (
                    <span className="text-orange-600 font-primary font-medium text-base">
                      In progress
                    </span>
                  ) : (
                    <span className="text-orange-600 font-primary font-medium text-base">
                      {" "}
                      Activate this domain
                    </span>
                  )}
                </p>
              )}
              {props?.description && (
                <p className="overflow-para md:text-xs my-2 pb-3 lg:text-xs overflow-wrap: break-word font-primary capitalize text-xs text-wrap: wrap text-gray-500 ">
                  {props.description}
                </p>
              )}
            </div>
          </Link>
        ) : (
          <div>
            <div className={`${props?.CardHeight}`}>
              {props.image ? (
                <img
                  className="h-full rounded-xl w-full object-center object-cover"
                  src={
                    props.image
                      ? `${process.env.REACT_APP_MEDIA_URL}/api/media/download${props?.image}`
                      : ""
                  }
                  alt={props.title}
                />
              ) : (
                <img
                  className="h-full rounded-xl w-full object-center object-contain"
                  src={`${process.env.REACT_APP_MEDIA_URL}/api/media/download}${props.image}`}
                  alt={props.title}
                />
              )}
            </div>
            <div>
              <p
                data-testid="domainTitleId"
                className="overflow-para lg:text-lg  text-base font-primary font-medium text-left capitalize text-gray-900 py-2 lg:pt-5  m-0"
              >
                {props.title}
              </p>
              {props.role === "Admin" ? (
                <p className="text-green-600 font-primary font-medium text-base">
                  <span className="text-black">Status:-</span>{" "}
                  {props.status === "Approved" ? (
                    <span className="text-green-600">Approved</span>
                  ) : props.status === "Denied" ? (
                    <span className="text-red-600 font-primary font-medium text-base">
                      Denied please retry
                    </span>
                  ) : props.status === "Pending" ? (
                    <span className="text-orange-600 font-primary font-medium text-base">
                      In progress
                    </span>
                  ) : (
                    <span className="text-orange-600 font-primary font-medium text-base">
                      {" "}
                      Activate this domain
                    </span>
                  )}
                </p>
              ) : (
                ""
              )}
              {props?.description && (
                <p className="overflow-para md:text-sm my-2 pb-3 lg:text-xs font-primary capitalize text-xs  text-gray-500 ">
                  {props.description}
                </p>
              )}
            </div>
          </div>
        )}
      </div>
      {props.route || props.role === "SuperAdmin" ? (
        <div
          className={`border-t border-brand-300 pt-5 flex ${
            props.route ? "justify-between" : "justify-end"
          } items-center`}
        >
          {props.route && props.route != "" ? (
            <Link to={props.route}>
              <div className="bg-black group hover:bg-transparent border border-black rounded-full flex items-center justify-center w-8 h-8 p-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-5 h-5 hover:text-black text-white"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
                  />
                </svg>
              </div>
            </Link>
          ) : (
            ""
          )}
          {props.role === "SuperAdmin" ? (
            <div className="flex flex-row justify-center space-x-2">
              <div data-testid={`edit ${props.title}`}>
                <EditButton editRoute={`${props?.editRoute}`} />
              </div>
              <div data-testid={`delete ${props.title}`}>
                <DeleteButton
                  response={props.deleteRes}
                  message={props.message}
                  uuid={props.uuid}
                  type={props?.type}
                  image={props?.image}
                />
              </div>
            </div>
          ) : null}
        </div>
      ) : (
        props.message && (
          <div className="flex flex-col justify-between">
            <p className="md:text-sm font-primary capitalize text-xs lg:text-xs text-gray-500 ">
              {props.message}
            </p>
          </div>
        )
      )}
    </div>
  );
};
